import {apiMiddleware} from './api/api.middleware'
import {actionSplitterMiddleware} from './actionSplitter/actionSplitter'
import {localStorageMiddleware} from './localStorage/localStorage.middleware'
import {analyticsMiddleware} from './analytics/analytics.middleware'
import {logsMiddleware} from './logs/logs.middleware'
import {notificationsMiddleware} from "./notifications/notifications.middleware";

export const coreMiddleware = [
    actionSplitterMiddleware,
    apiMiddleware,
    localStorageMiddleware,
    analyticsMiddleware,
    logsMiddleware,
    notificationsMiddleware
]
