import React from 'react';
import _ from 'lodash'
import {ListDocuments} from "../TypeDocument/ListDocuments";

const AddDocument = (props) => {
    const { setShowAddDoc, file, fetchNewDocument, query, idContrat, indexFile, maxIndex, codeProduit, formContext } = props

    const setNewDocInQueue = (value) => {
        fetchNewDocument({
            query,
            form: {
                doc_type: value,
                contract: idContrat,
                indexFile,
                form_type_origin: file.code,
                file_id: file.id,
                file: {
                    url: file.url,
                    extension: file.extension
                },
                status: file.status,
                maxIndex,
                form_context: formContext
            }
        })
        setShowAddDoc(false)
    }

    return (
        <div className={'mb-5'}>
            <div className="d-flex mt-3 mb-4">
                <i className="bi bi-arrow-left btn-rounded d-inline-block cursor-pointer me-3" onClick={() => setShowAddDoc(false)} />
                <p className="f-14 pt-2">Type de document</p>
            </div>

            {
                _.sortBy(ListDocuments(formContext, codeProduit), ['libelle']).map((doc, i) => (
                    <div className={"bg-dark-2 p-3 mt-3 text-white cursor-pointer hover-color-btn"} key={i} onClick={() => setNewDocInQueue(doc.code)}>
                        <i className="bi bi-file-earmark f-18 me-3" /> {doc.libelle} <i className={"bi bi-arrow-right float-end f-18"} />
                    </div>
                ))
            }
        </div>
    );
};

export default AddDocument;
