import {
    SET_IS_LOGIN,
    SET_LOADER,
    SET_MAINTENANCE,
    SET_ERROR, SET_INDEX_FILE, SET_MAX_INDEX_FILE, SET_NEW_FICHE_PRATIQUE_AFTER_TYPAGE,
} from '../../actions/app/ui/ui.actions'
import {SET_TOTAL_TASKS} from "../../actions/app/tasks/tasks.actions";

export const initStateUi = {
    login: false,
    maintenance: false,
    loading: {},
    error: {},
    totalTasks: 0,
    indexFile: 0,
    maxIndexFile:0
}

export const uiReducer = (ui = initStateUi, action = {}) => {
    const { payload, meta } = action

    switch (true){
        case action.type.includes(SET_LOADER):
            return {...ui, loading: {...ui.loading, [meta]: payload}}
        case action.type.includes(SET_ERROR):
            return {...ui, error: {...ui.error, [meta]: payload}}
        case action.type.includes(SET_IS_LOGIN):
            return {...ui, login: payload}
        case action.type.includes(SET_MAINTENANCE):
            return {...ui, maintenance : payload}
        case action.type.includes(SET_TOTAL_TASKS):
            return {...ui, totalTasks : payload.data}
        case action.type.includes(SET_INDEX_FILE):
            return {...ui, indexFile : payload}
        case action.type.includes(SET_MAX_INDEX_FILE):
            return {...ui, maxIndexFile : payload}

        default:
            return ui
    }
}
