import React from 'react';
import PropTypes from 'prop-types'
import {Field} from "formik";
import './radio.scss'

const Radio = (props) => {
    const { value, error, label, name, type, required, disabled, onClick, isCheckedAutomatic } = props

    let checked = null
    if(isCheckedAutomatic){
        checked = true
    }

    return (
        <div className={'input-radio position-relative'}>
            <Field
                type="radio"
                id={`${name}-${value}`}
                name={name}
                value={value}
                required={required}
                className={'position-absolute'}
                style={{zIndex: '-2'}}
                //checked={checked}
            />
            <label
                htmlFor={`${name}-${value}`}
                className={`btn-validate btn-validate-${type} ${type} f-22`}
                onClick={onClick}
            >
                <span>
                    {type === 'success' ? <i className="bi bi-check"></i> : <i className="bi bi-x"></i>}
                </span>
                {label}
            </label>
            {error &&
                <small className={'text-danger fst-italic'}>{error}</small>
            }
        </div>
    );
};

Radio.defaultProps = {
    type: "primary"
}

Radio.propTypes = {
    type: PropTypes.oneOf(['success', 'danger'])
}

export default Radio;
