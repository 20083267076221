import React, {useState, useEffect} from 'react';
import _ from 'lodash'
import Text from "../Inputs/Text/Text";
import {checkIban} from "../../../utils/function";
import {normalizeDate, normalizeIban, normalizeYear} from "../../../utils/normalize";

const Iban = (props) => {
    const { correction, file, handleBlur, label, values, name, handleChange, value, disabled, setFieldValue, nameBic, nameDomiciliation } = props
    const [errorIban, setErrorIban] = useState(false)
    const [ loadingIban, setLoadingIban ] = useState(false)

    useEffect(() => {
        if (correction && value && !errorIban && (values.iban?.[`${nameBic}`]?.state !== 1)) {
            fetchCheckIban(value)
        }
        return () => {
            setFieldValue(`iban`, {});
        }
    }, [])

    const changeData = (e) => {
        e.target.value = normalizeIban(e.target.value)

        handleChange(e)
    }

    const fetchCheckIban = async (value) => {
        setLoadingIban(true)
        let iban = await checkIban(value)
        setLoadingIban(false)

        /* Vérifie si le format de l'iban est correct */
        const codeValidationsSuccess = ['001', '002', '003', '004', '005', '006', '007'];
        const fieldValidations = ['account', 'country_support', 'iban', 'length', 'structure'];
        let error = null

        _.forEach(fieldValidations, field => {
            if (!_.includes(codeValidationsSuccess, iban.validations[field].code)){
                error = iban.validations[field].message
                return false;
            }
        });

        if(error === null){
            setFieldValue(nameBic, iban.bank_data.bic);
            setFieldValue(nameDomiciliation, iban.bank_data.bank);
            setFieldValue(`iban[${nameDomiciliation}][new_value]`, iban.bank_data.bank);
            setFieldValue(`iban[${nameBic}][new_value]`, iban.bank_data.bic);
            setFieldValue(`iban[${nameBic}][state]`, 1);
            setFieldValue(`iban[${nameDomiciliation}][state]`, 1);
        } else {
            setFieldValue(`iban[${nameBic}][state]`, 0);
            setFieldValue(`iban[${nameDomiciliation}][state]`, 0);
        }

        /* Vérifie si l'iban accepte les prélèvement */
        if (iban.sepa_data.SDD === "NO" || iban.sepa_data.SCT === "NO"){
            return setErrorIban("L'IBAN renseignée n'autorise pas les prélèvements bancaires")
        }

        return error ? setErrorIban(`Le format de l'IBAN n'est pas valide. <br /> ${error}`) : setErrorIban(null)
    }


    return (
        <div>
            <div className={`${correction && "d-flex"}`}>
                <div className="w-100">
                    <Text
                        isValid={!!value && (!correction) && values.iban?.[`${file.input_name}`]?.state}
                        type={file.type}
                        name={name}
                        onChange={e => changeData(e)}
                        onBlur={handleBlur}
                        value={value?.toUpperCase() || ""}
                        required
                        placeholder={file.label}
                        label={label}
                        maxLength={file.max_length}
                        disabled={disabled}
                        additional_label={file.additional_label}
                        style={{borderRadius: '8px 0 0 8px'}}
                    />
                </div>
                {
                    correction &&
                    <Text
                        type={'text'}
                        name={'isibancheck'}
                        onChange={e => changeData(e)}
                        onBlur={handleBlur}
                        className={'d-none'}
                        value={values.iban?.[`${nameBic}`]?.state === 1 ? 1 : undefined}
                        required
                    />

                }
                {
                    correction && <div
                        className={"btn-validate btn-validate-minor_changes cursor-pointer p-0 border-secondary border-start border-end-0 pt-3"}
                        onClick={() => fetchCheckIban(value)}
                        style={{minWidth: 120}}
                    >
                        {
                            loadingIban ? <div className="spinner-border" role="status" style={{width: 19, height: 19}}>
                                <span className="sr-only" />
                            </div> : "Vérifier l'IBAN"
                        }
                    </div>
                }
            </div>
            {
                correction && <div>
                    {
                        errorIban && <div className={'alert-danger alert mb-0'} dangerouslySetInnerHTML={{__html: errorIban}} />
                    }

                    {
                        errorIban === null && <div className={'alert m-0'} style={{background: '#1dcba7', color: '#fff'}}>
                            L'IBAN est valide
                        </div>
                    }
                </div>
            }
            {
                ((correction && !value) || (!correction && !value && values.data?.[`${file.input_name}`]?.state !== "0")) && <div>
                    <div className={'alert-danger alert mb-0'}>
                        Merci de renseigner un IBAN
                    </div>
                </div>
            }
            {
                correction && value && !errorIban && (values.iban?.[`${nameBic}`]?.state !== 1) && <div>
                    <div className={'alert-danger alert mb-0'}>
                        Merci de vérifier l'IBAN renseigné
                    </div>
                </div>
            }

        </div>
    );
};

export default Iban;
