import React from 'react';
import LargeSpinner from "../../../../Commun/Spinners/LargeSpinner/LargeSpinner";

const Logout = (props) => {
    return (
        <div className={'text-center'}>
            <p className={'mt-5'}>Déconnexion en cours...</p>
            <LargeSpinner />
        </div>
    );
};

export default Logout;
