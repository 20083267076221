import React from 'react';
import HeaderContainer from "../../Header/HeaderContainer";
import ImgDocument from "../WorkSpace/ImgDocument/ImgDocument";

const AllDocuments = ({files_url, task}) => {

    return (
        <div>
            <HeaderContainer>
                <div className={'col-8'}>
                    <div className={'text-center f-16'}>
                        <div className={'row'}>
                            <div className={'col-6  mt-1'}>
                                <p className={'f-12 text-white'}>{task.contract.souscripteur.titre} {task.contract.souscripteur.nom} - N° de téléphone du client : {task.contract.souscripteur.phone} <br/>Apporteur : {task.contract.apporteurs.apporteur1.raisonSociale} {task.contract.apporteur1 !== '43397' && '- N° du courtier : ' + task.contract.apporteurs.apporteur1.phone} </p>
                            </div>
                        </div>
                    </div>
                </div>
            </HeaderContainer>

            <div style={{marginTop: 120}} className={"row"}>
                {
                    files_url.map((document, index) => (
                        <div key={index} className={"col-6"}>
                            <h4>{document.libelle}</h4>
                            <ImgDocument file={document} />
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default AllDocuments;