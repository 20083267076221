import {connect} from 'react-redux'
import ReassignTask from "./ReassignTask";
import {
    getTasksReassignDocumentLink,
    getTasksReassignLink
} from "../../../../../../redux/selectors/links/links.selectors";
import {postReassignTask, postReassignTaskDocument} from "../../../../../../redux/actions/app/tasks/tasks.actions";
import {getPopupIdFirstTask} from "../../../../../../redux/selectors/tasks/tasks.selectors";

const mapStateToProps = (state) => {
    return {
        popup_id: getPopupIdFirstTask(state),
        queryReassign: getTasksReassignLink(state),
        queryReassignDocument: getTasksReassignDocumentLink(state)
    }
}

const mapDispatchToProps = {
    postReassignTaskDocument,
    postReassignTask
}

const FieldsDocumentContainer = connect(mapStateToProps, mapDispatchToProps)(ReassignTask)

export default FieldsDocumentContainer
