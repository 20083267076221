export const LINKS = '[Links]'

export const FETCH_LINKS = `${LINKS} Fetch`
export const SET_LINKS = `${LINKS} Set`

export const fetchLinks = ({query}) => ({
  type: FETCH_LINKS,
  payload: {
    data: query
  }
})

export const setLinks = ({links}) => ({
  type: SET_LINKS,
  payload: {
    data: links
  }
})
