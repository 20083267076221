import { createStore, applyMiddleware, compose } from 'redux'
import logger from 'redux-logger'
import { AppReducers } from '../reducers'
import {coreMiddleware} from '../middlewares/core'
import {appMiddleware} from "../middlewares/app"
import {APP_ENV} from "../../config/constants";

const middlewares = [...appMiddleware, ...coreMiddleware]

if (APP_ENV !== 'production') {
    middlewares.push(logger)
}

const stores = createStore(
    AppReducers,
    compose(
        applyMiddleware(...middlewares)
    )
)

export default stores
