import {createSelector} from 'reselect'
import _ from "lodash";

const documentsSelector = state => state.documents

export const getDocumentsData = createSelector(
    documentsSelector,
    documents => documents
)

export const getDocumentsVerifPieces = createSelector(
    documentsSelector,
    documents => _.filter(documents.data, ['conseiller_document_category_id', 1])
)
