import {connect} from 'react-redux'
import {compose} from 'redux'
import App from "./App";
import {fetchLinks, LINKS} from "../../../redux/actions/app/links/links.actions";
import loader from "../../Commun/Loader/loader";
import {APP_URL_PASS} from "../../../config/constants";
import {getLoadingEntity} from "../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = state => {
    return {
        loaded: getLoadingEntity(state, LINKS) === false
    }
}

const mapDispatchToProps = {
    load: () => fetchLinks({query: APP_URL_PASS})
}


const AppContainer = compose(
    connect(mapStateToProps, mapDispatchToProps),
    loader()
)(App)

export default AppContainer
