import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
    fetchFirstTasks,
    FETCH_FIRST_TASKS,
    postRetryDocument,
    postValidateDocument,
    POST_RETRY_DOCUMENT,
} from '../../../../../redux/actions/app/tasks/tasks.actions';
import {
    getDocumentRetryLink,
    getTaskAnFirstLink,
    getTaskByIdLink,
    getTasksAnValidateFieldsLink,
} from '../../../../../redux/selectors/links/links.selectors';
import {
    getCodeProduitContratFirstTask,
    getFilesUrlFirstTask,
    getFirstTask,
    getIdContractFirstTask,
    getOrdreFirstTask,
    getPopupIdFirstTask,
    getTerminateUrlTask,
    getTiersIdTask,
} from '../../../../../redux/selectors/tasks/tasks.selectors';
import { getIndexFileUi, getLoadingEntity, getMaxIndexFileUi } from '../../../../../redux/selectors/ui/ui.selectors';
import loader from '../../../../Commun/Loader/loader';
import WorkSpace from './WorkSpace';

const mapStateToProps = (state) => {
    let url = window.location.pathname;

    return {
        query: getTasksAnValidateFieldsLink(state),
        indexFile: getIndexFileUi(state),
        maxIndexFile: getMaxIndexFileUi(state),
        loading: getLoadingEntity(state, FETCH_FIRST_TASKS),
        loaded: getLoadingEntity(state, FETCH_FIRST_TASKS) === false,
        queryTaskById: getTaskByIdLink(state),
        queryFirstTask: getTaskAnFirstLink(state),
        files_url: getFilesUrlFirstTask(state),
        popup_id: getPopupIdFirstTask(state),
        contract_id: getIdContractFirstTask(state),
        task: getFirstTask(state),
        terminateUrl: getTerminateUrlTask(state),
        initialValues: {
            tiers: getTiersIdTask(state),
            contract: getIdContractFirstTask(state),
            chrono_id: getOrdreFirstTask(state),
            form_context: url.split('/')[2].toUpperCase(),
        },
        codeProduit: getCodeProduitContratFirstTask(state),
        formContext: url.split('/')[2].toUpperCase(),
        taskId: url.split('/')[3] || null,
        urlRetryDocument: getDocumentRetryLink(state),
        loadingRetryDocument: getLoadingEntity(state, POST_RETRY_DOCUMENT),
    };
};

const mapDispatchToProps = {
    fetchFirstTasks,
    postValidateDocument,
    postRetryDocument,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {
        queryFirstTask,
        queryTaskById,
        query,
        maxIndexFile,
        indexFile,
        terminateUrl,
        formContext,
        taskId,
        urlRetryDocument,
    } = stateProps;
    const { fetchFirstTasks, postValidateDocument, postRetryDocument } = dispatchProps;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        postValidateDocument: (form) =>
            postValidateDocument({
                query,
                form,
                otherData: {
                    terminateUrl,
                    indexFile,
                    maxIndexFile,
                    taskId,
                },
            }),
        postRetryDocument: (id) =>
            postRetryDocument({
                query: _.replace(urlRetryDocument, '{id}', id),
            }),
        load: () => {
            taskId
                ? fetchFirstTasks({
                      query: _.replace(_.replace(queryTaskById, '{id}', taskId), '{context}', formContext),
                      otherData: {
                          taskId,
                      },
                  })
                : fetchFirstTasks({ query: _.replace(queryFirstTask, '{context}', formContext) });
        },
    };
};

const WorkSpaceContainer = compose(connect(mapStateToProps, mapDispatchToProps, mergeProps), loader())(WorkSpace);

export default WorkSpaceContainer;
