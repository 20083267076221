import React from 'react';
import _ from "lodash";
import FaqCard from "../../../../Commun/Card/FaqCard";

const Faq = (props) => {
    const { faqs, setShowFaq } = props

    return (
        <>
            <button className={`btn btn-outline-secondary mb-5`} onClick={() => setShowFaq(false)}>
                <i className="bi bi-arrow-left me-2"></i>
                Accueil Aide
            </button>
            <h2 className={'pb-2'}>Questions fréquentes</h2>
            <p>Accéder aux descriptifs des documents et consultez les questions fréquentes concernant l’utilisation de votre Espace Affaires Nouvelles.</p>
            {
                _.map(faqs.data, (faq,i) => (
                    <FaqCard faq={faq} i={i}/>
                ))
            }
        </>
    );
};

export default Faq;
