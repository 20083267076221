import React from 'react';
import {Routes, BrowserRouter, Route} from 'react-router-dom'
import PrivateRouteContainer from "./PrivateRoute/PrivateRouteContainer";
import AppContainer from "./App/AppContainer";
import LoginContainer from "./Login/LoginContainer";

const Router = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path={'/maintenance'} element={<p>Votre espace est en cours de maintenance.</p>} />
                <Route path={'/connexion'} element={<LoginContainer/>} />
                <Route path={'*'} element={
                    <PrivateRouteContainer>
                        <AppContainer />
                    </PrivateRouteContainer>
                } />
            </Routes>
        </BrowserRouter>

    );
};

export default Router;
