import {connect} from 'react-redux'
import {compose} from 'redux'
import Dashboard from "./Dashboard";
import loader from "../../../../../Commun/Loader/loader";
import {getDashboardLink} from "../../../../../../redux/selectors/links/links.selectors";
import {getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {FETCH_DASHBOARD, fetchDashboard} from "../../../../../../redux/actions/app/dashboard/dashboard.actions";
import {getTaskAn, getTaskRes, getTaskResReassign, getTaskAnReassign} from "../../../../../../redux/selectors/dashboard/dashboard.selectors";

const mapStateToProps = state => {
    return {
        query: getDashboardLink(state),
        loading: getLoadingEntity(state, FETCH_DASHBOARD),
        loaded: getLoadingEntity(state, FETCH_DASHBOARD) === false,
        tasksAn: getTaskAn(state),
        tasksRes: getTaskRes(state),
        tasksResReassign: getTaskResReassign(state),
        tasksAnReassign: getTaskAnReassign(state),
    }
}

const mapDispatchToProps = {
    fetchDashboard
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query } = stateProps
    const { fetchDashboard } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => {
            fetchDashboard({query})
        }
    }
}

const DashboardContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Dashboard)

export default DashboardContainer
