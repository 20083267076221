import React from 'react';
import {Field} from "formik";
import PropTypes from 'prop-types'

const Select = (props) => {
    const { value, error, children, label, required, additional_label } = props

    return (
        <div className={'form-floating'}>
            <Field as="select" {...props}
                   className={`form-select ${value ? 'valid' : ''}`}
                   id="floatingSelect"
                   required={required}
            >
                {children}
            </Field>
            {label &&
                <label htmlFor="floatingSelect"><b>{additional_label}</b> {label}{required ? <sup className={'text-danger'}>*</sup> : ''}</label>
            }
            {error && <small className={'text-danger fst-italic'}>{error}</small>}
        </div>
    );
};

Select.defaultProps = {
    label: undefined
};

Select.propTypes = {
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }),
    children: PropTypes.node
};


export default Select;
