import React, {useState} from 'react'

const FaqCard = (props) => {
    const { faq, i } = props
    const [open, setOpen] = useState(false)

    return (
        <div className={`accordion bg-dark p-3 mt-3 text-white cursor-pointer`} key={i} onClick={() => setOpen(!open)}>
            <div className="accordion-item">
                <h2 className="accordion-header" id={`heading${i}`}>
                    <button
                        className={`accordion-button ${open ? 'collapsed' : ''}`} type="button" data-bs-toggle="collapse"
                        data-bs-target={`#collapse${i}`} aria-expanded="true"
                        aria-controls={`collapse${i}`}
                    >
                        <i className="bi bi-chat pe-2 f-22"></i>{faq.question}
                    </button>
                </h2>
                <div id={`collapse${i}`} className={`accordion-collapse collapse ${open && 'show'}`}
                     aria-labelledby={`heading${i}`}>
                    <div className="accordion-body" dangerouslySetInnerHTML={{__html: faq.content_html}}/>
                </div>
            </div>
        </div>
    )
}

export default FaqCard;
