/**
 * Normalize input Date DD/MM/YYYY
 * @param value
 * @returns {string}
 */
export const normalizeDate = value => {
    if (!value) {
        return value
    }

    const separateur = '/'

    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 2) {
        return onlyNums
    }
    if (onlyNums.length <= 4) {
        return `${onlyNums.slice(0, 2)}${separateur}${onlyNums.slice(2)}`
    }
    return `${onlyNums.slice(0, 2)}${separateur}${onlyNums.slice(2, 4)}${separateur}${onlyNums.slice(
        4,
        8
    )}`
}

/**
 * Normalize input Date MM/YYYY
 * @param value
 * @returns {string}
 */
export const normalizeYear = value => {
    if (!value) {
        return value
    }

    return value.replace(/[^\d]/g, '')
}

export const normalizeIban = (value) => {
    if (!value) {
        return value;
    }

    const separateur = '';

    const onlyNums = value.replace(/[!@#$%^&*\-(),.?":{}|<>]/g, '').toUpperCase();

    if (onlyNums.length <= 4) {
        return onlyNums;
    }
    if (onlyNums.length <= 8) {
        return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 8)}`;
    }

    if (onlyNums.length <= 12) {
        return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 8)}${separateur}${onlyNums.slice(8, 12)}`;
    }

    if (onlyNums.length <= 16) {
        return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 8)}${separateur}${onlyNums.slice(
            8,
            12,
        )}${separateur}${onlyNums.slice(12, 16)}`;
    }

    if (onlyNums.length <= 20) {
        return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 8)}${separateur}${onlyNums.slice(
            8,
            12,
        )}${separateur}${onlyNums.slice(12, 16)}${separateur}${onlyNums.slice(16, 20)}`;
    }

    if (onlyNums.length <= 24) {
        return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 8)}${separateur}${onlyNums.slice(
            8,
            12,
        )}${separateur}${onlyNums.slice(12, 16)}${separateur}${onlyNums.slice(16, 20)}${separateur}${onlyNums.slice(
            20,
            24,
        )}`;
    }

    return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 8)}${separateur}${onlyNums.slice(
        8,
        12,
    )}${separateur}${onlyNums.slice(12, 16)}${separateur}${onlyNums.slice(16, 20)}${separateur}${onlyNums.slice(
        20,
        24,
    )}${separateur}${onlyNums.slice(24, 27)}`;
};