import {linksMiddleware} from "./links/links.middleware";
import {loginMiddleware} from "./login/login.middleware";
import {tasksMiddleware} from "./tasks/tasks.middleware";
import {userMiddleware} from "./user/user.middleware";
import {faqMiddleware} from "./support/faq.middleware";
import {documentsMiddleware} from "./support/documents.middleware";
import {dashboardMiddleware} from "./dashboard/dashboard.middleware";
import {settingsMiddleware} from "./settings/settings.middleware";

export const appMiddleware = [
    linksMiddleware,
    loginMiddleware,
    tasksMiddleware,
    userMiddleware,
    faqMiddleware,
    documentsMiddleware,
    dashboardMiddleware,
    settingsMiddleware
]
