import {connect} from 'react-redux'
import FieldsDocument from "./FieldsDocument";
import {getIndexFileUi, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {
    POST_TYPAGE_DOCUMENT,
    POST_VALIDATE_FIELDS,
    postReassignTaskDocument
} from "../../../../../../redux/actions/app/tasks/tasks.actions";

const mapStateToProps = (state) => {
    return {
        loadingTypage: getLoadingEntity(state, POST_TYPAGE_DOCUMENT),
        loading: getLoadingEntity(state, POST_VALIDATE_FIELDS),
        indexFile: getIndexFileUi(state)
    }
}

const mapDispatchToProps = {
    postReassignTaskDocument
}

const FieldsDocumentContainer = connect(mapStateToProps, mapDispatchToProps)(FieldsDocument)

export default FieldsDocumentContainer
