import React from 'react';
import {Formik} from "formik";
import Text from "../../Commun/Inputs/Text/Text";
import './login.scss'
import LogoCheckDoc from '../../../assets/logo/checkdoc-blue.png'


const Login = (props) => {
    const { postLogin, loading, isLogin, initialValues, errorState} = props

    if (isLogin){
        window.location = '/'
    }

    return (
        <div id={'login-page'}>
            <div className={"container"}>
                <div className={'row'}>
                    <div className={'bloc-login col-5 text-center'}>
                        <div className={'p-3'}>
                            <img src={LogoCheckDoc} alt="APRIL Moto" className={'img-fluid'} width={120}/>
                            <p className={'pt-3 pb-1'}>Bienvenue sur votre espace<br/>Affaires nouvelles</p>
                        </div>
                        <div className={'p-3 pb-5'}>
                            <Formik initialValues={initialValues} onSubmit={values => postLogin(values)}>
                                {({handleSubmit, errors, touched, handleChange, handleBlur, values}) => (
                                    <form onSubmit={handleSubmit} className={'row'}>
                                        <div className='col-8 offset-2 mb-4'>
                                            <Text
                                                type={'text'}
                                                name="username"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.username}
                                                required
                                                label='Identifiant'
                                                placeholder='Saisissez votre identifiant'
                                            />
                                        </div>
                                        <div className='col-8 offset-2'>
                                            <Text
                                                type="password"
                                                name="password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.password}
                                                required
                                                label='Mot de passe'
                                                placeholder='Saisissez votre mot de passe'
                                            />
                                        </div>
                                        <div className='col-8 offset-2'>
                                            <button type="submit" className={`btn btn-block text-center`} style={{minWidth: 200}}>
                                                {
                                                    loading ?
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                                        : <>Je me connecte <span className={'d-inline-block mx-2'}>➜</span></>

                                                }
                                            </button>
                                        </div>

                                        {errorState && <p className='col-12 error mt-2'>Votre identifiant / mot de passe sont incorrects</p>}
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
