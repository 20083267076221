import React from 'react';
import { Link } from "react-router-dom";
import HeaderContainer from "../../Header/HeaderContainer";
import DashboardContainer from "./Dashboard/DashboardContainer";
import _ from 'lodash'

const Home = (props) => {
    const { totalTasks, message, permissions, settings } = props

    return (
        <>
            <div className={'col-12'}>
                <HeaderContainer />
            </div>
            <div className={'col-8 offset-2'}>
                <div className={'container-fluid wrap-content'}>
                    {
                        message && <div className={'row'}>
                            <div className="alert alert-warning mt-5 text-center" role="alert" dangerouslySetInnerHTML={{__html: message}}/>
                        </div>
                    }
                    <div className={'row'}>
                        <p className={'outer-title mt-4'}>Mes tâches AN</p>
                        <div className={'mt-3 box-card p-4'}>
                            <div className={'row'}>
                                <div className={'col-3'}>
                                    <span className={'f-12'}>Tâche{totalTasks['AN'].a_traiter > 1 && 's'} à traiter</span>
                                    <p><span className={'font-white f-42'}>{totalTasks['AN'].a_traiter}</span></p>
                                </div>
                                <div className={'col-3'}>
                                    <span className={'f-12'}>Mes tâches traitées</span>
                                    <p><span className={'font-white f-42'}>{totalTasks['AN'].today}</span></p>
                                </div>
                                <div className={'col-3'}>
                                    <span className={'f-12'}>Tâches traitées par l'équipe</span>
                                    <p><span className={'font-white f-42'}>{totalTasks['AN'].total}</span></p>
                                </div>
                                <div className={'col-3 text-end'}>
                                    <Link to={'ma-tache/an'} className={`${totalTasks['AN'].a_traiter < 1 && 'disabled'} btn btn-primary btn-lg d-inline-block mt-2`}>Traiter une AN <span className={'d-inline-block'}>➜</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {settings.toString() === "1" && <div className={'row'}>
                        <p className={'outer-title mt-5'}>Mes tâches RES</p>
                        <div className={'mt-3 box-card p-4'}>
                            <div className={'row'}>
                                <div className={'col-3'}>
                                    <span
                                        className={'f-12'}>Tâche{totalTasks['RES'].a_traiter > 1 && 's'} à traiter</span>
                                    <p><span className={'font-white f-42'}>{totalTasks['RES'].a_traiter}</span></p>
                                </div>
                                <div className={'col-3'}>
                                    <span className={'f-12'}>Mes tâches traitées</span>
                                    <p><span className={'font-white f-42'}>{totalTasks['RES'].today}</span></p>
                                </div>
                                <div className={'col-3'}>
                                    <span className={'f-12'}>Tâches traitées par l'équipe</span>
                                    <p><span className={'font-white f-42'}>{totalTasks['RES'].total}</span></p>
                                </div>
                                <div className={'col-3 text-end'}>
                                    <Link to={'ma-tache/res'}
                                          className={`${totalTasks['RES'].a_traiter < 1 && 'disabled'} btn btn-primary btn-lg d-inline-block mt-2`}>
                                        Traiter une RES <span className={'d-inline-block'}>➜</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>}
                    {_.includes(permissions, 'read manager dashboard afn') &&
                        <DashboardContainer/>
                    }
                </div>
            </div>
        </>
    );
};

export default Home;
