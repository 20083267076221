import {GET_SETTINGS, setSettings, SETTINGS} from '../../../actions/app/settings/settings.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'

export const settingsMiddleware = ({dispatch}) => next => action => {
    next(action)
    switch (action.type) {

        case GET_SETTINGS:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: SETTINGS}),
                setLoader({state: true, entity: SETTINGS})
            ])
            break

        case `${SETTINGS} ${API_SUCCESS}`:
            next([
                setSettings({data: action.payload.data}),
                setError({state: false, entity: SETTINGS}),
                setLoader({state: false, entity: SETTINGS})
            ])
            break

        case `${SETTINGS} ${API_ERROR}`:
            next([
                setError({state: true, entity: SETTINGS}),
                setLoader({state: false, entity: SETTINGS})
            ])
            break

        default:
            break
    }
    return null
}
