import React from 'react';
import _ from "lodash";

const Documents = (props) => {
    const { documents, setShowDocuments } = props

    return (
        <>
            <button className={`btn btn-outline-secondary mb-5`} onClick={() => setShowDocuments(false)}>
                <i className="bi bi-arrow-left me-2"></i>
                Accueil Aide
            </button>
            <h2 className={'pb-2'}>Les documents</h2>
            <p>Retrouvez toutes les informations nécessaires pour vérifier les informations des pièces envoyés par les clients</p>
            {
                _.map(documents, (doc, i) => (
                    <div className={"bg-dark p-3 mt-3 text-white cursor-pointer hover-color-btn"} key={i}>
                        <a href={doc.file} target={'_blank'} rel="noopener noreferrer">
                            <i className="bi bi-file-earmark f-18 me-3" /> {doc.title} <i className={"bi bi-arrow-right float-end f-18"} />
                        </a>
                    </div>
                ))
            }
        </>
    );
};

export default Documents;
