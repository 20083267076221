import {connect} from 'react-redux'
import {compose} from 'redux'
import Faq from "./Faq";
import loader from "../../../../Commun/Loader/loader";
import {getFaqLink} from "../../../../../redux/selectors/links/links.selectors";
import {FETCH_FAQ, fetchFaq} from "../../../../../redux/actions/app/support/faq.actions";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {getFaqsData} from "../../../../../redux/selectors/support/faq.selectors";

const mapStateToProps = state => {
    return {
        query: getFaqLink(state),
        loading: getLoadingEntity(state, FETCH_FAQ),
        loaded: getLoadingEntity(state, FETCH_FAQ) === false,
        faqs: getFaqsData(state)
    }
}

const mapDispatchToProps = {
    fetchFaq
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query } = stateProps
    const { fetchFaq } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => {
            fetchFaq({query})
        }
    }
}

const FaqContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Faq)

export default FaqContainer
