import {combineReducers} from 'redux'
import {linksReducer} from "./links/links.reducer";
import {uiReducer} from "./ui/ui.reducer";
import {tasksReducer} from "./tasks/tasks.reducer";
import {userReducer} from "./user/user.reducer";
import {faqReducer} from "./support/faq.reducer";
import {documentsReducer} from "./support/documents.reducer";
import {dashboardReducer} from "./dashboard/dashboard.reducer";
import {settingsReducer} from "./settings/settings.reducer";

export const AppReducers = combineReducers({
    links: linksReducer,
    ui: uiReducer,
    task: tasksReducer,
    user: userReducer,
    faqs: faqReducer,
    documents: documentsReducer,
    dashboard: dashboardReducer,
    settings: settingsReducer
})
