export const SETTINGS = '[Settings]'

export const GET_SETTINGS = `${SETTINGS} Get`
export const SET_SETTINGS = `${SETTINGS} Set`

export const getSettings = ({query}) => ({
    type: GET_SETTINGS,
    payload: {
        data: query
    }
})

export const setSettings = ({data}) => ({
    type: SET_SETTINGS,
    payload: {
        data
    }
})
