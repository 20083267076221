import {connect} from 'react-redux'
import {bindActionCreators, compose} from "redux";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {LOGOUT, postLogout} from "../../../../../redux/actions/app/login/login.actions";
import loader from "../../../../Commun/Loader/loader";
import {getUserLogoutLink} from "../../../../../redux/selectors/links/links.selectors";
import Logout from "./Logout";

const mapStateToProps = state => {
    return {
        uri: getUserLogoutLink(state),
        logout: getLoadingEntity(state, LOGOUT) === false,
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    logout: (query) => postLogout({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { logout } = dispatchProps
    const { uri } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => logout(uri)
    }
}

const LogoutContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Logout)

export default LogoutContainer
