import {LOG_ACTION} from "../../../actions/core/logs/logs.actions";
import {apiRequest} from "../../../actions/core/api/api.actions";
import {APP_URL_PASS} from "../../../../config/constants";

export const logsMiddleware = ({dispatch}) => next => action => {
    next(action)
    if (action.type.includes(LOG_ACTION)){
        const { data } = action.payload

        let dataForm = new FormData()

        for (let [key, value] of Object.entries(data)) {
            dataForm.set([key], value)
        }

        dispatch(apiRequest({body: dataForm, method: 'POST', url: `${APP_URL_PASS}/user/log`, entity: 'LOG_ACTIVITY_PRO'}))
    }
}
