import React, {useEffect, useState} from 'react';
import {localStorageAMO} from "../../../redux/selectors/localStorage/localStorage";
import SmallSpinner from "../Spinners/SmallSpinner";

const APIFrame = ({src, ...otherProps}) => {
    const [objectUrl, setObjectUrl] = useState(null)
    const [error, setError] = useState(null)

    let url = new URL(src)
    url.searchParams.delete('access_token');
    url = url.toString()

    const urlSlices = /^.*\/(.*)\.([^\?]*)\??.*$/.exec(url)
    const fileName = `${urlSlices[1]}.${urlSlices[2]}`

    useEffect(() => {
        let headers = new Headers();

        const tokenUser = localStorageAMO.token
        headers.append('Authorization', tokenUser);

        fetch(url, {headers})
            .then((response) => {
                if (response.ok) {
                    response.blob()
                        .then(blob => setObjectUrl(window.URL.createObjectURL(blob)))
                } else {
                    setError(`Erreur lors du téléchargement du fichier ${fileName}`)
                }
            })
    }, [src])

    if (error) {
        return <p className={'text-danger'}>{error}</p>
    }

    if (!objectUrl) {
        return <div><SmallSpinner size={12} /> T&eacute;l&eacute;chargement du fichier {fileName}...</div>
    }

    return <object data={objectUrl} {...otherProps} title={fileName} />
}

export default APIFrame;
