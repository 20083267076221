import {connect} from 'react-redux'
import {compose} from 'redux'
import _ from 'lodash'
import AllDocuments from "./AllDocuments";
import {
    FETCH_FIRST_TASKS,
    fetchFirstTasks
} from "../../../../../redux/actions/app/tasks/tasks.actions";
import {getTaskByIdLink} from "../../../../../redux/selectors/links/links.selectors";
import {getFilesUrlFirstTask, getFirstTask} from "../../../../../redux/selectors/tasks/tasks.selectors";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import loader from "../../../../Commun/Loader/loader";

const mapStateToProps = (state) => {
    let url = window.location.pathname

    return {
        loading: getLoadingEntity(state, FETCH_FIRST_TASKS),
        loaded: getLoadingEntity(state, FETCH_FIRST_TASKS) === false,
        queryTaskById: getTaskByIdLink(state),
        files_url: getFilesUrlFirstTask(state),
        task: getFirstTask(state),
        formContext: url.split('/')[2].toUpperCase(),
        taskId: url.split('/')[3] || null
    }
}

const mapDispatchToProps = {
    fetchFirstTasks
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { queryTaskById, formContext, taskId } = stateProps
    const { fetchFirstTasks } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchFirstTasks({
            query:  _.replace(_.replace(queryTaskById, '{id}', taskId), '{context}', formContext),
            otherData: {
                taskId
            }
        })
    }
}

const AllDocumentsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(AllDocuments)

export default AllDocumentsContainer
