import {
    LOGIN,
    LOGOUT,
    POST_LOGIN,
    POST_LOGOUT,
    postLoginSuccess,
    postLoginFailed,
    POST_COOKIE_LOGIN
} from '../../../actions/app/login/login.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setIsLogin, setLoader} from '../../../actions/app/ui/ui.actions'
import {clearLocalStorage, setLocalStorage} from '../../../actions/core/localStorage/localStorage.actions'
import React from "react";

export const loginMiddleware = () => next => action => {
    next(action)

    const { payload } = action
    let storage

    switch (action.type){
        case POST_LOGIN:
            next([
                apiRequest({body: payload.body, method: 'POST', url: payload.data, entity: LOGIN}),
                setLoader({state: true, entity: LOGIN})
            ])
            break

        case `${LOGIN} ${API_SUCCESS}`:
            const { token_type, access_token, home_message_afn } = payload.data

            storage = {
                token: `${token_type} ${access_token}`,
                home_message: home_message_afn
            }

            next([
                setLocalStorage({entity: LOGIN, storage}),
                setIsLogin({state: true, entity: LOGIN}),
                setError({state: false, entity: LOGIN}),
                setLoader({state: false, entity: LOGIN}),
                postLoginSuccess(),
            ])
            break

        case `${LOGIN} ${API_ERROR}`:
            next([
                setError({state: true, entity: LOGIN}),
                setLoader({state: false, entity: LOGIN}),
                postLoginFailed(),
            ])
            break

        case POST_LOGOUT:
            next([
                apiRequest({method: 'POST', url: payload.data, entity: LOGOUT}),
                setLoader({state: true, entity: LOGOUT})
            ])
            break

        case `${LOGOUT} ${API_SUCCESS}`:
            next([
                setError({state: false, entity: LOGOUT}),
                clearLocalStorage({entity: LOGOUT}),
                setLoader({state: false, entity: LOGOUT})
            ])
            window.location.href = '/login'
            break;

        case `${LOGOUT} ${API_ERROR}`:
            next([
                setError({state: true, entity: LOGOUT}),
                setLoader({state: false, entity: LOGOUT})
            ])
            break;

        case POST_COOKIE_LOGIN:
            storage = {
                token: `Bearer ${payload.body}`,
                home_message: ''
            }

            next([
                setLocalStorage({entity: LOGIN, storage}),
                setIsLogin({state: true, entity: LOGIN}),
                setLoader({state: false, entity: LOGIN}),
            ])
            break;

        default:
            break
    }
    return null
}
