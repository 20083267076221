import React, {useState} from 'react';
import FaqContainer from "./Faq/FaqContainer";
import DocumentsContainer from "./Documents/DocumentsContainer";

const Support = (props) => {
    const { setShow, show } = props
    const [ showFaq, setShowFaq ] = useState(false)
    const [ showDocuments, setShowDocuments ] = useState(false)

    return (
        <>
            <div className={`offcanvas offcanvas-end ${show}`} tabIndex="-1" id="faqSidebar" aria-labelledby="faqSidebarLabel">
                <div className="offcanvas-header">
                    <h5 id="faqSidebarLabel"><i className="bi bi-question ps-1"></i> Besoin d'aide ?</h5>

                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" onClick={(e) => setShow('')}><i className="bi bi-x"></i></button>
                </div>
                <div className="offcanvas-body">
                    {(!showFaq && !showDocuments) &&
                        <>
                            <h2 className={'pb-2'}>Bienvenue dans l'espace d'aide & support</h2>
                            <p>Accéder aux descriptifs des documents et consultez les questions fréquentes concernant l'utilisation de votre espace</p>


                            <div className={'bg-dark p-3 mt-3 text-white cursor-pointer hover-color-btn'}
                                onClick={() => setShowFaq(true)}
                            >
                                <i className="bi bi-file-earmark f-18 me-3" />
                                Questions fréquentes
                                <i className={"bi bi-arrow-right float-end f-18"} />
                            </div>
                            <div className={'bg-dark p-3 mt-3 text-white cursor-pointer hover-color-btn'}
                                 onClick={() => setShowDocuments(true)}
                            >
                                <i className="bi bi-file-earmark f-18 me-3" />
                                Fiches pratiques
                                <i className={"bi bi-arrow-right float-end f-18"} />
                            </div>
                        </>
                    }
                    {showFaq && <FaqContainer setShowFaq={setShowFaq} />}
                    {showDocuments && <DocumentsContainer setShowDocuments={setShowDocuments} />}
                </div>
            </div>
            <div className={`fade offcanvas-backdrop ${show}`}></div>
        </>
    );
};

export default Support;
