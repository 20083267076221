import {createSelector} from 'reselect'

const dashboardSelector = state => state.dashboard

export const getDashboardData = createSelector(
    dashboardSelector,
    dashboard => dashboard
)

export const getTaskAn = createSelector(
    dashboardSelector,
    dashboard => dashboard[0] ? dashboard[0]['AN'] : {}
)

export const getTaskRes = createSelector(
    dashboardSelector,
    dashboard => dashboard[0] ? dashboard[0]['RES'] : {}
)

export const getTaskAnReassign = createSelector(
    dashboardSelector,
    dashboard => dashboard.an_today_reassign
)

export const getTaskResReassign = createSelector(
    dashboardSelector,
    dashboard => dashboard.res_today_reassign
)
