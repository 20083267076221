import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {Navigate, useSearchParams} from 'react-router-dom'
import _ from "lodash";

const PrivateRoute = (props) => {
    useEffect(() => {
        window.scroll(0,0)
    })
    const { login, token, fetchUser, fail, query, children, setCookieLogin } = props
    const [searchParams, setSearchParams] = useSearchParams();

    if (!token && !login) {
        const access_token = searchParams.get('access_token');
        if(access_token){
            setCookieLogin(access_token);
            setSearchParams();
        } else {
            return <Navigate to={'/connexion'} />
        }
    } else if (fail && token && !login){
        return <Navigate to={'/connexion'} />
    }

    if (fail || !token) {
        window.location.reload()
        return null
    }

    if (!login) {
        return fetchUser({query})
    }

    return children

}

PrivateRoute.propTypes = {
    component: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
}

export default PrivateRoute
