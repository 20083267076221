/* https://sweetalert2.github.io/ */
import Swal from 'sweetalert2'
import {SET_NOTIFICATION} from '../../../actions/core/notifications/notifications.actions'

export const notificationsMiddleware = () => next => action => {
    next(action)

    if (action.type.includes(SET_NOTIFICATION)) {


        Swal.fire(action.meta).then((result) => {
            if (result.isConfirmed && action.meta.successFunction) {
                action.meta.successFunction();
            }
        })

    }
}
