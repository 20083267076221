import {connect} from 'react-redux'
import Header from "./Header";
import {getNameUser} from "../../../../redux/selectors/user/user.selectors";

const mapStateToProps = state => {
    return {
        name: getNameUser(state)
    }
}

const HeaderContainer = connect(mapStateToProps)(Header)

export default HeaderContainer
