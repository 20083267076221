import {connect} from 'react-redux'
import {compose} from 'redux'
import Documents from "./Documents";
import loader from "../../../../Commun/Loader/loader";
import {getDocumentsLink} from "../../../../../redux/selectors/links/links.selectors";
import {FETCH_DOCUMENTS, fetchDocuments} from "../../../../../redux/actions/app/support/documents.actions";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {getDocumentsVerifPieces} from "../../../../../redux/selectors/support/documents.selectors";

const mapStateToProps = state => {
    return {
        query: getDocumentsLink(state),
        loading: getLoadingEntity(state, FETCH_DOCUMENTS),
        loaded: getLoadingEntity(state, FETCH_DOCUMENTS) === false,
        documents: getDocumentsVerifPieces(state)
    }
}

const mapDispatchToProps = {
    fetchDocuments
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query } = stateProps
    const { fetchDocuments } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => {
            fetchDocuments({query})
        }
    }
}

const DocumentsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Documents)

export default DocumentsContainer
