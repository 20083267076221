import axios from "axios";
import {GRAVITEE_API_KEY, GRAVITEE_URL} from "../constants/constants";
import moment from "moment";

/**
 * Check iban
 * use
 * async fetchCheckIban(value){
 *      let iban = await checkIban(value)
 *      console.log(iban)
 * }
 * @param value
 * @returns {object}
 */
export const checkIban = (value) => {
    return new Promise(resolve => {
        axios({
            method: "GET",
            url: `${GRAVITEE_URL}/ibancheck/?iban=${value}`,
            headers: {
                "X-Gravitee-Api-Key": GRAVITEE_API_KEY
            },
        }).then((response) => {
            resolve(response.data);
        }).catch(error => {
            console.log(error)
        });
    });
}


/**
 * Fonction maxDateToday pour les Fields de redux-form
 * @param value
 * @returns {String}
 */
export const maxDateToday = value => moment(value, "DD/MM/YYYY", true) > moment() ? 'La date renseignée est dans le futur.' : undefined;
