import {SET_DASHBOARD} from '../../actions/app/dashboard/dashboard.actions'

export const dashboardReducer = (state = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_DASHBOARD) {
        return payload.data
    } else {
        return state
    }
}
