import {SET_ADD_DOCUMENT, SET_FIRST_TASKS, SET_NEW_FORM_AFTER_TYPAGE} from "../../actions/app/tasks/tasks.actions";
import _ from 'lodash'


export const tasksReducer = (state = {}, action = {}) => {
    const {payload} = action

    switch (action.type) {
        case SET_FIRST_TASKS:
            return payload.data
        case SET_NEW_FORM_AFTER_TYPAGE:
            _.set(state, `files_url[${payload.index}].fields`, payload.data.fields)
            _.set(state, `files_url[${payload.index}].fiche_pratique`, payload.data.fiche_pratique)
            return state

        case SET_ADD_DOCUMENT:
            const newState = state

            newState.files_url.splice(payload.index+1, 0, payload.data)

            return newState
        default:
            return state
    }
}
