import React, {useEffect} from 'react';
import _ from 'lodash'
import LargeSpinner from "../../../../../Commun/Spinners/LargeSpinner/LargeSpinner";
import FieldDocument from "./FieldDocument/FieldDocument";
import ReassignDocumentButton from "./ReassignDocumentButton/ReassignDocumentButton";

const FieldsDocument = (props) => {
    let { popup_id, file: { id, fields = [], code, fiche_pratique }, values, loadingTypage, loading, indexFile, setFieldValue, showFormReassign, setShowFormReassign, reassignDocument, setReassignDocument, maxIndexFile, formContext, paniereFormReassign, setPaniereFormReassign, taskId } = props

    useEffect(() => {
        setFieldValue('data', {})
        setFieldValue('file_id', id)
        setFieldValue('form_type_origin', code)
        setFieldValue('start_timer', Math.floor(Date.now() / 1000))
        setFieldValue('doc_type', fields[0]?.doc_type)
    }, [indexFile, props.file])

    // Utilisé pour le dernier document
    useEffect(() => {
        if(!values.form_type_origin){
            setFieldValue('form_type_origin', code)
        }
        if(!values.popup_id){
            setFieldValue('popup_id', popup_id)
        }
        if(!values.file_id){
            setFieldValue('file_id', id)
        }
        if(!values.start_timer){
            setFieldValue('start_timer', Math.floor(Date.now() / 1000))
        }
        if(!values.doc_type){
            setFieldValue('doc_type', fields[0]?.doc_type)
        }
    })

    useEffect(() => {
        fields.forEach(f => {
            if (f.editable === true) {
                setFieldValue(`data[${f.input_name}][new_value]`, values.data?.[f.input_name]?.new_value || f.default_value_automation)
            }

            if (f.is_automation_validation === true) {
                setFieldValue(`data[${f.input_name}][state]`, '1');
            } else if (f.is_automation_validation === false) {
                setFieldValue(`data[${f.input_name}][state]`, '0');
            }
            if (f.automatic_validation === true && f.editable === false) {
                setFieldValue(`data[${f.input_name}][state]`, '1');
            }
        })

    }, [fields])

    const TextButton = maxIndexFile === indexFile ? `Valider et clôturer la tâche <i class="bi bi-check-lg ms-2" />` : `Valider la pièce <span className={'d-inline-block ms-2'}>➜</span>`
    const ClassButton = maxIndexFile === indexFile ? 'btn-primary btn-primary-green' : 'btn-primary'

    return (
        <>
            {
                loadingTypage ? <LargeSpinner /> : <div>
                    {
                        fiche_pratique?.file && <div className={'d-flex flex-row-reverse pb-5'}>
                            <a href={fiche_pratique?.file} download className="d-inline-block btn-text-hover" target={'_blank'} rel={'noreferrer'}>
                                <div className="wrap">
                                    <span className={'text'}>Voir la fiche pratique</span>
                                    <span className={'icon'}><i className="bi bi-question ps-1"></i></span>
                                </div>
                            </a>
                        </div>
                    }

                    {
                        _.map(fields, (f, i) => (
                            <FieldDocument key={i} f={f} values={values} {...props}/>
                        ))
                    }

                    <hr/>
                    {
                        Object.keys(fields).length > 0 ? <div className="d-flex flex-row-reverse mt-5 justify-content-between align-items-start">
                                <button className={`btn ${ClassButton}`} type={loading ? "button" : "submit"} style={{minWidth: 150}}>
                                    {
                                        loading
                                            ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                            : <p dangerouslySetInnerHTML={{__html: TextButton}}/>
                                    }
                                </button>
                                {
                                    !taskId &&
                                     <ReassignDocumentButton
                                         formContext={formContext}
                                         paniereFormReassign={paniereFormReassign}
                                         setPaniereFormReassign={setPaniereFormReassign}
                                         setShowFormReassign={setShowFormReassign}
                                         reassignDocument={reassignDocument}
                                         showFormReassign={showFormReassign}
                                         setReassignDocument={setReassignDocument}
                                         lastDocument={maxIndexFile === indexFile}
                                    />
                                }
                            </div>
                            : <div className={'alert alert-danger'}>Le typage automatique du document n’existe pas. Merci de retyper manuellement le document avec la liste en haut à gauche</div>
                    }
                </div>
            }
        </>

    );
};

export default FieldsDocument;
