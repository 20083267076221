import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import AppProvider from "./AppProvider";
import {APP_ENV} from "./config/constants";
import './assets/scss/global.scss';
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";


window.dataLayer = window.dataLayer || [];

window.dataLayer.push({
    'environnement': APP_ENV
})

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.,
    tracesSampleRate: 0.01,
    environment: APP_ENV,
    enabled: true
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    APP_ENV ? <AppProvider /> : <h1>.env requis à la racine du projet, un npm start est nécessaire.</h1>
);




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
