export const FAQ = '[Faq]'

export const FETCH_FAQ = `${FAQ} Fetch`
export const SET_FAQ = `${FAQ} Set`

export const fetchFaq = ({query}) => ({
    type: FETCH_FAQ,
    payload: {
        data: query
    }
})

export const setFaq = ({data}) => ({
    type: SET_FAQ,
    payload: {
        data: data
    }
})
