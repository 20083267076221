import {connect} from 'react-redux'
import AddDocument from "./AddDocument";
import {fetchNewDocument} from "../../../../../../redux/actions/app/tasks/tasks.actions";
import {getTasksChangeTypeLink} from "../../../../../../redux/selectors/links/links.selectors";
import {
    getCodeProduitContratFirstTask,
    getIdContractFirstTask
} from "../../../../../../redux/selectors/tasks/tasks.selectors";
import {getIndexFileUi, getMaxIndexFileUi} from "../../../../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = state => {
    let url = window.location.pathname
    return {
        query: getTasksChangeTypeLink(state),
        idContrat: getIdContractFirstTask(state),
        indexFile: getIndexFileUi(state),
        maxIndex: getMaxIndexFileUi(state),
        codeProduit: getCodeProduitContratFirstTask(state),
        formContext: url.split('/')[2].toUpperCase()
    }
}

const mapDispatchToProps = {
    fetchNewDocument
}
const AddDocumentContainer = connect(mapStateToProps, mapDispatchToProps)(AddDocument)

export default AddDocumentContainer
