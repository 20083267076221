import React from 'react'
import { Provider } from 'react-redux'
import stores from './redux/stores'
import Router from './components/Router/Router'
import {configMoment} from "./config/moment";

configMoment()

const AppProvider = () => {
    return (
        <Provider store={stores}>
            <Router />
        </Provider>
    )
}

export default AppProvider
