import React from 'react';
import _ from "lodash";
import moment from "moment";

const Dashboard = (props) => {
    const { tasksAn, tasksRes, tasksResReassign, tasksAnReassign } = props

    const todayDocumentsAnValue = tasksAn.documents_today.document_ok+tasksAn.documents_today.document_ko
    const pourcentageTodayAnOk = Math.round(Number(tasksAn.documents_today.document_ok)/Number(todayDocumentsAnValue)*100)
    const pourcentageTodayAnKo = Math.round(Number(tasksAn.documents_today.document_ko)/Number(todayDocumentsAnValue)*100)

    const todayDocumentsResValue = tasksRes.documents_today.document_ok+tasksRes.documents_today.document_ko
    const pourcentageTodayResOk = Math.round(Number(tasksRes.documents_today.document_ok)/Number(todayDocumentsResValue)*100)
    const pourcentageTodayResKo = Math.round(Number(tasksRes.documents_today.document_ko)/Number(todayDocumentsResValue)*100)

    const pourcentageAnNiv2 = Math.round((Number(tasksAn.niv2_today)+Number(tasksAnReassign))/Number(tasksAn.task_today)*100)
    const pourcentageResNiv2 = Math.round((Number(tasksRes.niv2_today)+Number(tasksResReassign))/Number(tasksRes.task_today)*100)

    return (
        <>
            <div className={'row'}>
                <p className={'outer-title mt-5 ps-0'}>Tâche AN de l'équipe</p>
                <div className={'col-3 mt-3 ps-0'}>
                    <p className={'box-card p-3 h-100'}>
                        <span className={'f-12'}>Tâches<br/>traitées hier</span>
                        <br/>
                        <span className={'font-white f-32'}>{tasksAn.yesterday}</span>
                    </p>
                </div>
                <div className={'col-3 mt-3'}>
                    <p className={'box-card p-3 h-100'}>
                        <span className={'f-12'}>Tâches<br/>traitées aujourd'hui</span>
                        <br/>
                        <span className={'font-white f-32'}>{tasksAn.today}</span>
                    </p>
                </div>
                <div className={'col-2 mt-3'}>
                    <p className={'box-card p-3 h-100'}>
                        <span className={'f-12'}>Tâche<br/>la plus ancienne</span>
                        <br/>
                        <span className={'font-white f-26'}>{moment(tasksAn.older).format('DD/MM/YYYY')}</span>
                    </p>
                </div>
                <div className={'col-4 mt-3 pe-0'}>
                    <div className={'box-card p-3 h-100'}>
                        <p className={'outer-title mb-2'}>Documents AN traités aujourd'hui</p>
                        {todayDocumentsAnValue > 0 ?
                            <div className="progress">
                                <div className="progress-bar bg-success"
                                     role="progressbar"
                                     style={{width: pourcentageTodayAnOk + '%'}}
                                     aria-valuenow={tasksAn.documents_today.document_ok}
                                     aria-valuemin="0"
                                     aria-valuemax={todayDocumentsAnValue}
                                >
                                    <strong className={'m-2'}>{tasksAn.documents_today.document_ok} OK</strong>
                                </div>
                                <div className="progress-bar bg-danger"
                                     role="progressbar"
                                     style={{width: pourcentageTodayAnKo + '%'}}
                                     aria-valuenow={tasksAn.documents_today.document_ko}
                                     aria-valuemin="0"
                                     aria-valuemax={todayDocumentsAnValue}
                                >
                                    <strong>{tasksAn.documents_today.document_ko} KO</strong>
                                </div>
                            </div>
                            : <p>Pas de données pour aujourd'hui</p>
                        }
                    </div>
                </div>
            </div>
            <div className={'row'}>
                <p className={'outer-title mt-3 ps-0'}>Statistiques du jour des transferts de tâches AN</p>
                <div className={'col-4 mt-3 ps-0'}>
                    <p className={'box-card p-3'}>
                        <span className={'f-12'}>AN_NIV2<br/> crées aujourd'hui</span>
                        <br/>
                        <span className={'font-white f-26'}>{tasksAn.niv2_today+tasksAnReassign}</span><span className={'f-12'}> soit {pourcentageAnNiv2 > 0 ? pourcentageAnNiv2 : '0'}%</span>
                    </p>
                </div>
                <div className={'col-4 mt-3'}>
                    <p className={'box-card p-3'}>
                        <span className={'f-12'}>AN_NIV2<br/> crées aujourd'hui automatiquement</span>
                        <br/>
                        <span className={'font-white f-26'}>{tasksAn.niv2_today}</span>
                    </p>
                </div>
                <div className={'col-4 mt-3 pe-0'}>
                    <p className={'box-card p-3'}>
                        <span className={'f-12'}>AN_NIV2<br/> crées aujourd'hui manuellement</span>
                        <br/>
                        <span className={'font-white f-26'}>{tasksAnReassign}</span>
                    </p>
                </div>
            </div>

            <div className={'row'}>
                <p className={'outer-title mt-5 ps-0'}>Tâche RES de l'équipe</p>
                <div className={'col-3 mt-3  ps-0'}>
                    <p className={'box-card p-3 h-100'}>
                        <span className={'f-12'}>Tâches<br/>traitées hier</span>
                        <br/>
                        <span className={'font-white f-32'}>{tasksRes.yesterday}</span>
                    </p>
                </div>
                <div className={'col-3 mt-3'}>
                    <p className={'box-card p-3 h-100'}>
                        <span className={'f-12'}>Tâches<br/>traitées aujourd'hui</span>
                        <br/>
                        <span className={'font-white f-32'}>{tasksRes.today}</span>
                    </p>
                </div>
                <div className={'col-2 mt-3'}>
                    <p className={'box-card p-3 h-100'}>
                        <span className={'f-12'}>Tâche<br/>la plus ancienne</span>
                        <br/>
                        <span className={'font-white f-26'}>{moment(tasksRes.older).format('DD/MM/YYYY')}</span>
                    </p>
                </div>
                <div className={'col-4 mt-3 pe-0'}>
                    <div className={'box-card p-3 h-100'}>
                        <p className={'outer-title mb-2'}>Documents RES traités aujourd'hui</p>
                        {todayDocumentsResValue > 0 ?
                            <div className="progress">
                                <div className="progress-bar bg-success"
                                     role="progressbar"
                                     style={{width: pourcentageTodayResOk + '%'}}
                                     aria-valuenow={tasksRes.documents_today.document_ok}
                                     aria-valuemin="0"
                                     aria-valuemax={todayDocumentsResValue}
                                >
                                    <strong className={'m-2'}>{tasksRes.documents_today.document_ok} OK</strong>
                                </div>
                                <div className="progress-bar bg-danger"
                                     role="progressbar"
                                     style={{width: pourcentageTodayResKo + '%'}}
                                     aria-valuenow={tasksRes.documents_today.document_ko}
                                     aria-valuemin="0"
                                     aria-valuemax={todayDocumentsResValue}
                                >
                                    <strong>{tasksRes.documents_today.document_ko} KO</strong>
                                </div>
                            </div>
                            : <p>Pas de données pour aujourd'hui</p>
                        }
                    </div>
                </div>
            </div>
            <div className={'row'}>
                <p className={'outer-title mt-3 ps-0'}>Statistiques du jour des transferts de tâches RES</p>
                <div className={'col-4 mt-3 ps-0'}>
                    <p className={'box-card p-3'}>
                        <span className={'f-12'}>RES_NIV2<br/> crées aujourd'hui</span>
                        <br/>
                        <span className={'font-white f-26'}>{tasksRes.niv2_today+tasksResReassign}</span><span className={'f-12'}> soit {pourcentageResNiv2 > 0 ? pourcentageResNiv2 : '0'}%</span>
                    </p>
                </div>
                <div className={'col-4 mt-3'}>
                    <p className={'box-card p-3'}>
                        <span className={'f-12'}>RES_NIV2<br/> crées aujourd'hui automatiquement</span>
                        <br/>
                        <span className={'font-white f-26'}>{tasksRes.niv2_today}</span>
                    </p>
                </div>
                <div className={'col-4 mt-3 pe-0'}>
                    <p className={'box-card p-3'}>
                        <span className={'f-12'}>RES_NIV2<br/> crées aujourd'hui manuellement</span>
                        <br/>
                        <span className={'font-white f-26'}>{tasksResReassign}</span>
                    </p>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-6 ps-0'}>
                    <p className={'outer-title mt-5 mb-3'}>Nombre de tâches AN traitées par l'équipe aujourd'hui</p>
                    <div className={'box-card p-3'}>
                        {
                            (tasksAn.users_today).length > 0 ?
                                _.map((tasksAn.users_today), (t, i) => (
                                    <div className={'row d-flex justify-content-between'} key={i}>
                                        <strong className={'col-6'}>{t.realisateur}</strong>
                                        <div className={'col-6 text-end'}>
                                            <strong className={'text-white'}>{t.popups}  </strong>
                                            <span className={'text-white f-12'}> tâche{t.popups > 1 && 's'}</span>
                                        </div>
                                        {i+1 !== (tasksAn.users_today).length && <hr className={'mt-3 mb-3'}/>}
                                    </div>
                                ))
                                : <p>Pas de données pour aujourd'hui</p>
                        }
                    </div>
                </div>
                <div className={'col-6 pe-0'}>
                    <p className={'outer-title mt-5 mb-3'}>Nombre de tâches RES traitées par l'équipe aujourd'hui</p>
                    <div className={'box-card p-3'}>
                        {
                            (tasksRes.users_today).length > 0 ?
                                _.map((tasksRes.users_today), (t, i) => (
                                    <div className={'row d-flex justify-content-between'} key={i}>
                                        <strong className={'col-6'}>{t.realisateur}</strong>
                                        <div className={'col-6 text-end'}>
                                            <strong className={'text-white'}>{t.popups}  </strong>
                                            <span className={'text-white f-12'}> tâche{t.popups > 1 && 's'}</span>
                                        </div>
                                        {i+1 !== (tasksRes.users_today).length && <hr className={'mt-3 mb-3'}/>}
                                    </div>
                                ))
                                : <p>Pas de données pour aujourd'hui</p>
                        }
                    </div>
                </div>
            </div>
            <div className={'row mb-5'}>
                <div className={'col-6 mt-5 ps-0'}>
                    <p className={'outer-title mt-4'}>Détail des tâches AN en attente de traitement</p>
                    <div className={'box-card'}>
                        <div className={'p-3 mt-4'}>
                            <table className="table text-white">
                                <thead>
                                <tr>
                                    <th scope="col">Nombre de popups</th>
                                    <th scope="col">Date</th>
                                </tr>
                                </thead>
                                <tbody>
                                {tasksAn.details !== [] && (tasksAn.details).map((d, index) =>
                                    <tr key={index} className={'text-white'}>
                                        <td>{d.nbPopups}</td>
                                        <td>{moment(d.date).format('L')}</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className={'col-6 mt-5 pe-0'}>
                    <p className={'outer-title mt-4'}>Détail des tâches RES en attente de traitement</p>
                    <div className={'box-card'}>
                        <div className={'p-3 mt-4'}>
                            <table className="table text-white">
                                <thead>
                                <tr>
                                    <th scope="col">Nombre de popups</th>
                                    <th scope="col">Date</th>
                                </tr>
                                </thead>
                                <tbody>
                                {tasksRes.details !== [] && (tasksRes.details).map((d, index) =>
                                    <tr key={index} className={'text-white'}>
                                        <td>{d.nbPopups}</td>
                                        <td>{moment(d.date).format('L')}</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
