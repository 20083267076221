import {connect} from 'react-redux'
import PrivateRoute from './PrivateRoute'
import {bindActionCreators, compose} from 'redux'
import loader from '../../Commun/Loader/loader'
import {fetchUser, USER} from '../../../redux/actions/app/user/user.actions'
import {localStorageAMO} from '../../../redux/selectors/localStorage/localStorage'
import {getLogin, getErrorEntity, getLoading, getLoadingEntity} from '../../../redux/selectors/ui/ui.selectors'
import {APP_URL_PASS} from "../../../config/constants";
import {setCookieLogin} from "../../../redux/actions/app/login/login.actions";

const mapStateToProps = state => {
    return {
        login: getLogin(state),
        fail: getErrorEntity(state, USER),
        query: `${APP_URL_PASS}/user`,
        token: localStorageAMO && localStorageAMO.token ? localStorageAMO.token : null,
        loaded: getLoading(state) !== undefined && !getLoadingEntity(state, USER)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchUser: ({query}) => fetchUser({query: `${query}?refresh=true`}),
    setCookieLogin: (token) => setCookieLogin({token})
}, dispatch)

const PrivateRouteContainer = compose(
    connect(mapStateToProps, mapDispatchToProps),
    loader()
)(PrivateRoute)

export default PrivateRouteContainer
