import React, {useState} from 'react';
import './img_document.scss'
/* Document par defaut pour recette */
import {APP_ENV} from "../../../../../../config/constants";
import A from "../../../../../../assets/img/ImgDocument/doc/doc-code-a.pdf"
import AA from "../../../../../../assets/img/ImgDocument/doc/doc-code-aa.png"
import B from "../../../../../../assets/img/ImgDocument/doc/doc-code-b.pdf"
import C from "../../../../../../assets/img/ImgDocument/doc/doc-code-c.jpg"
import D from "../../../../../../assets/img/ImgDocument/doc/doc-code-d.pdf"
import E from "../../../../../../assets/img/ImgDocument/doc/doc-code-e.jpg"
import F from "../../../../../../assets/img/ImgDocument/doc/doc-code-f.jpg"
import FF from "../../../../../../assets/img/ImgDocument/doc/doc-code-f.jpg"
import G from "../../../../../../assets/img/ImgDocument/doc/doc-code-g.png"
import H from "../../../../../../assets/img/ImgDocument/doc/doc-code-h.jpg"
import I from "../../../../../../assets/img/ImgDocument/doc/doc-code-i.jpg"
import J from "../../../../../../assets/img/ImgDocument/doc/doc-code-j.jpg"
import K from "../../../../../../assets/img/ImgDocument/doc/doc-code-k.jpg"
import L from "../../../../../../assets/img/ImgDocument/doc/doc-code-l.jpg"
import M from "../../../../../../assets/img/ImgDocument/doc/doc-code-m.jpg"
import N from "../../../../../../assets/img/ImgDocument/doc/doc-code-n.jpg"
import O from "../../../../../../assets/img/ImgDocument/doc/doc-code-o.jpg"
import P from "../../../../../../assets/img/ImgDocument/doc/doc-code-p.jpg"
import Q from "../../../../../../assets/img/ImgDocument/doc/doc-code-q.jpg"
import R from "../../../../../../assets/img/ImgDocument/doc/doc-code-r.jpg"
import S from "../../../../../../assets/img/ImgDocument/doc/doc-code-s.jpg"
import T from "../../../../../../assets/img/ImgDocument/doc/doc-code-t.pdf"
import U from "../../../../../../assets/img/ImgDocument/doc/doc-code-u.pdf"
import V from "../../../../../../assets/img/ImgDocument/doc/doc-code-v.jpg"
import W from "../../../../../../assets/img/ImgDocument/doc/doc-code-w.pdf"
import X from "../../../../../../assets/img/ImgDocument/doc/doc-code-x.pdf"
import Z from "../../../../../../assets/img/ImgDocument/doc/doc-code-z.pdf"
import APIFrame from "../../../../../Commun/APILink/APIFrame";
import APIImage from "../../../../../Commun/APILink/APIImage";
/* Document par defaut pour recette */

const ImgDocument = (props) => {
    const { file } = props
    const [rotate, setRotate] = useState(0)
    const [zoom, setZoom] = useState(500)
    let url = file.url
    let extension = file.extension

    /* Document par defaut pour recette */
/*    if (APP_ENV !== "production"){
        const imgForRelease = {A, AA, B, C, D, E, F, G, H, I, J, K, L, M, N, O, P, Q, R, S, T, U, V, W, X, Z, FF}
        url = imgForRelease[file.code]
        extension = url.substr(url.lastIndexOf('.') + 1)
    }*/
    /* Document par defaut pour recette */

    let styleImg = {
        transform: `rotate(${rotate}deg)`,
        width: `${zoom}px`,
        display: 'block',
        margin: 'auto'
    }

    return (
        <div className={'pt-4 col-12'}>
            {
                extension.toLowerCase() === 'pdf' ?
                    <div className={''} style={{marginBottom: 30}}>
                        <APIFrame src={url} title={`${file.code}`} style={{height: 700, width: '100%', marginBottom: 30}}/>
                    </div>
                    :
                    <div>
                        <div className="overflow-auto bg-dark-2" style={{height: 600}}>
                            <div className="icon-images d-flex flex-column h-100 justify-content-between ps-3">
                                <div className={'justify-content-top mt-3'}>
                                    <div className={'p-3 btn-img btn-1'} onClick={() => setZoom(zoom + 100)} title={'Zoom avant'}>+</div>
                                    <div className={'p-3 btn-img btn-2'} onClick={() => setZoom(zoom - 100)} title={'Zoom arrière'}>-</div>
                                </div>
                                <div className={'justify-content-end mb-3'}>
                                    <div className={'p-3 btn-img btn-3'} onClick={() => setZoom(500)} title={'Ajuster à la page'}>
                                        <i className="bi bi-fullscreen" /></div>
                                    <div className={'p-3 btn-img btn-4'} onClick={() => setRotate(rotate + 90)} title={'Faire pivoter vers la droite'}>↻</div>
                                </div>
                            </div>
                            <APIImage src={`${url}`} style={styleImg} alt={'Document'} />
                        </div>
                    </div>
            }
        </div>
    );
};

export default ImgDocument;
