import {SET_FAQ} from '../../actions/app/support/faq.actions'

export const faqReducer = (state = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_FAQ) {
        return payload.data
    } else {
        return state
    }
}
