import {createSelector} from 'reselect'

const linksSelector = state => state.links.links

/* USER */
export const getUserLink = createSelector(
    linksSelector,
    links => links['user.get']?.uri
)

export const getUserLogoutLink = createSelector(
    linksSelector,
    links => links['user.logout']?.uri
)

/* Tasks */
export const getTasksAnTotalLink = createSelector(
    linksSelector,
    links => links['tasks.an.total']?.uri
)

export const getTaskAnFirstLink = createSelector(
    linksSelector,
    links => links['tasks.an.first']?.uri
)

export const getTaskByIdLink = createSelector(
    linksSelector,
    links => links['tasks.checkdoc.get']?.uri
)

export const getTasksAnValidateFieldsLink = createSelector(
    linksSelector,
    links => links['tasks.an.validate_fields']?.uri
)

export const getTasksChangeTypeLink = createSelector(
    linksSelector,
    links => links['tasks.change_type']?.uri
)

export const getTasksReassignLink = createSelector(
    linksSelector,
    links => links['tasks.reassign']?.uri
)

export const getTasksReassignDocumentLink = createSelector(
    linksSelector,
    links => links['tasks.reassign_document']?.uri
)

export const getDocumentRetryLink = createSelector(
    linksSelector,
    links => links['document.retry']?.uri
)

/* FAQ */

export const getFaqLink = createSelector(
    linksSelector,
    links => links['conseillers.faq.afn']?.uri
)

export const getDocumentsLink = createSelector(
    linksSelector,
    links => links['conseillers.documents']?.uri
)

/* DASHBOARD */

export const getDashboardLink = createSelector(
    linksSelector,
    links => links['dashboard.afn']?.uri
)

export const settingsLink = createSelector(
    linksSelector,
    links => links['rules.setting.get'].uri
)
