import {createSelector} from 'reselect'

/* UI */
const uiSelector = state => state.ui

/* Maintenance */
export const getMaintenance = createSelector(
    uiSelector,
    ui => ui.maintenance
)

/* Login */
export const getLogin = createSelector(
    uiSelector,
    ui => ui.login
)

/* Loading */
const loadingEntitySelector = (state, entity) => state.ui.loading[entity]

export const getLoading = createSelector(
    uiSelector,
    ui => ui.loading
)

export const getLoadingEntity = createSelector(
    loadingEntitySelector,
    loading => loading
)

/* Error */
const errorEntitySelector = (state, entity) => state.ui.error[entity]

export const getErrorEntity = createSelector(
    errorEntitySelector,
    error => error
)

/* TASKS */
export const getTotalTasks = createSelector(
    uiSelector,
    ui => ui.totalTasks
)

/* INDEX */
export const getIndexFileUi = createSelector(
    uiSelector,
    ui => ui.indexFile
)

export const getMaxIndexFileUi = createSelector(
    uiSelector,
    ui => ui.maxIndexFile
)
