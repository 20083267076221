import React, { useState } from 'react';

const ReassignDocumentButton = (props) => {
    const { formContext, lastDocument, setShowFormReassign, setReassignDocument, setPaniereFormReassign, paniereFormReassign, reassignDocument, showFormReassign } = props;

    let options = [];

    if (formContext === 'RES') {
        options = [
            { label: 'Réaffecter en AN', value: 'AN' },
            { label: 'Réaffecter au niveau 2', value: 'RES_NIV2' },
            { label: 'Réaffecter en attente', value: 'TACHE_ATT' },
            { label: 'Réaffecter en INDEM', value: 'INDEM' },
            { label: 'Réaffecter en récla', value: 'RECLA' },
        ];
    } else if (formContext === 'AN') {
        options = [
            { label: 'Réaffecter au niveau 2', value: 'AN_NIV2' },
            { label: 'Réaffecter en RES', value: 'RES_VENTE' },
            { label: 'Réaffecter en attente', value: 'TACHE_ATT' },
            { label: 'Réaffecter en INDEM', value: 'INDEM' },
            { label: 'Réaffecter en récla', value: 'RECLA' },
        ];
    }
    const handleReassign = () => {
        setShowFormReassign(true);
        setReassignDocument(true);
    };

    return (
        <div>
            <button
                type="button"
                onClick={handleReassign}
                className="btn btn-outline-secondary"
            >
                Réaffecter et clôturer la tâche
            </button>
            {showFormReassign && (
                <div>
                    <p className={'mt-5'}>Séléctionner la panière pour réaffecter</p>
                    <select
                        value={paniereFormReassign}
                        onChange={(e) => setPaniereFormReassign(e.target.value)}
                        className="form-select"
                    >
                        <option value="">Sélectionnez une panière</option>
                        {options.map((option, index) => (
                            <option key={index} value={option.value}>{option.label}</option>
                        ))}
                    </select>
                </div>
            )}
        </div>
    );
};

export default ReassignDocumentButton;
