import React from 'react';
import './checkbox.scss'
import '../Radio/radio.scss'
import {Field} from "formik";

const Checkbox = (props) => {
    const { value, error, label, name, type, required, disabled, onClick } = props

    return (
        <div className={'input-radio position-relative'}>
            <Field
                type="checkbox"
                id={`${name}-${value}`}
                name={name}
                checked={value}
                required={required}
                className={'position-absolute'}
                style={{zIndex: '-2'}}
                {...props}
            />
            <label
                htmlFor={`${name}-${value}`}
                className={`btn-validate btn-validate-minor_changes minor_changes_${value}  f-22`}
                onClick={onClick}
            >
                <span><i className="bi bi-pencil"></i></span>{label}
            </label>
            {error &&
                <small className={'text-danger fst-italic'}>{error}</small>
            }
        </div>
    );
};

export default Checkbox;
