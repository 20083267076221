import React from 'react';
import Page from "./Page/Page";

const App = () => {

    return (
        <div className={'container-fluid'}>
            <div className={'row'}>
                <Page />
            </div>
        </div>
    );
};

export default App;
