import React from 'react';
import {localStorageAMO} from "../../../redux/selectors/localStorage/localStorage";
import Swal from 'sweetalert2'
import axios from "axios";

const truncateMiddle = (fullStr) => {
    if (!fullStr) {
        return ''
    }
    if (fullStr.length < 16) {
        return fullStr
    }
    return fullStr.slice(0, 8) + '...' + fullStr.slice(-8)
}

const APILink = (props) => {
    let url = new URL(props.href)
    url.searchParams.delete('access_token');
    url = url.toString()

    const downloadWithAuth = async (e) => {
        e.preventDefault()

        let anchor = document.createElement("a");
        document.body.appendChild(anchor);

        // const urlSlices = /^.*\/(.*)\.([^\?]*)\??.*$/.exec(url)
        // const fileName = `${urlSlices[1]}.${urlSlices[2]}`

        const loadingToast = Swal.fire({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            },
            // titleText: `Téléchargement du fichier ${truncateMiddle(fileName)}...`$
            titleText: `Téléchargement du fichier...`
        });

        const tokenUser = localStorageAMO.token

        const response = await axios.get(url, {headers: {
            'Authorization': tokenUser,

            }, responseType: 'blob'});
        const blob = response.data;

        if (blob) {
            let objectUrl = window.URL.createObjectURL(blob);
            anchor.href = objectUrl;

            if (props.download) {
                anchor.download = props.fileName;
            } else {
                anchor.target = props.target || "_blank";
            }
            anchor.click();

            setTimeout(function() {
                document.body.removeChild(anchor);
                window.URL.revokeObjectURL(objectUrl);
            }, 100);
        } else {
            Swal.fire({
                toast: true,
                position: 'top',
                icon: 'error',
                title: 'Téléchargement échoué',
                confirmButtonText: 'J\'ai compris'
            });
        }

        loadingToast.close()

        props.onClick && props.onClick(e)
    }

    if (url.indexOf('pass.april-moto.com') < 0 && url.indexOf('pass-recette.april-moto.com') < 0 && url.indexOf('pass-live.april-moto.com') < 0 && url.indexOf('pass-recette2.april-moto.com') < 0 && url.indexOf('localhost:8008') < 0) {
        // ce n'est pas un dl du pass, pas d'auth
        return <a {...props}>
            {props.children}
        </a>
    }

    const {onClick, download, target, fileName, ...otherProps} = props;

    return <a onClick={downloadWithAuth} {...otherProps}>
        {props.children}
    </a>
}

export default APILink;
