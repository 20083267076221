import {connect} from 'react-redux'
import {compose} from "redux";
import Login from "./Login";
import {getErrorEntity, getLoadingEntity, getLogin} from "../../../redux/selectors/ui/ui.selectors";
import {LOGIN, postLogin} from "../../../redux/actions/app/login/login.actions";

const mapStateToProps = state => {
    return {
        loading: getLoadingEntity(state, LOGIN),
        initialValues: { username: '', password: '' },
        isLogin: getLogin(state),
        errorState: getErrorEntity(state, LOGIN),
    }
}

const mapDispatchToProps = {
    postLogin
}

const LoginContainer = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(Login)

export default LoginContainer