export const TASKS = '[Tasks]'
export const TYPAGE_DOCUMENT = '[Typage Document]'
export const VALIDATE_FIELDS = '[Validate Document]'
export const ADD_DOCUMENT = '[Add Document]'
export const RETRY_DOCUMENT = '[Retry Document]'

export const FETCH_TOTAL_TASKS = `${TASKS} Fetch Total`
export const SET_TOTAL_TASKS = `${TASKS} Set Total`
export const FETCH_FIRST_TASKS = `${TASKS} Fetch First`
export const SET_FIRST_TASKS = `${TASKS} Set First`
export const POST_CLOSE_TASK = `${TASKS} Post Close`
export const POST_REASSIGN_TASK = `${TASKS} Post Reassign`
export const POST_REASSIGN_TASK_DOCUMENT = `${TASKS} Post Reassign Document`

export const POST_TYPAGE_DOCUMENT = `${TYPAGE_DOCUMENT} Post`

export const SET_NEW_FORM_AFTER_TYPAGE = `${TYPAGE_DOCUMENT} Set form after Typage`

export const POST_VALIDATE_FIELDS = `${VALIDATE_FIELDS} Post`

export const FETCH_ADD_DOCUMENT = `${ADD_DOCUMENT} Fetch Document`
export const SET_ADD_DOCUMENT = `${ADD_DOCUMENT} Set Document`

export const POST_RETRY_DOCUMENT = `${RETRY_DOCUMENT} Post`

/* TASKS */
export const fetchTotalTasks = ({query}) => ({
    type: FETCH_TOTAL_TASKS,
    payload: {
        data: query
    }
})

export const setTotalTasks = ({data}) => ({
    type: SET_TOTAL_TASKS,
    payload: {
        data
    }
})

export const fetchFirstTasks = ({query, otherData = {}}) => ({
    type: FETCH_FIRST_TASKS,
    payload: {
        data: query,
        otherData
    }
})

export const setFirstTasks = ({data}) => ({
    type: SET_FIRST_TASKS,
    payload: {
        data
    }
})

export const postCloseTasks = ({query, otherData}) => ({
    type: POST_CLOSE_TASK,
    payload: {
        data: query,
        otherData
    }
})

/* TYPAGE_DOCUMENT */
export const postTypageDocument = ({query, form}) => ({
    type: POST_TYPAGE_DOCUMENT,
    payload:{
        data: query,
        body: form
    }
})

export const setNewFormAfterTypage = ({data, index, fiche_pratique}) => ({
    type: SET_NEW_FORM_AFTER_TYPAGE,
    payload: {
        data, index, fiche_pratique
    }
})

/* VALIDATE_FIELDS */
export const postValidateDocument = ({query, form, otherData}) => ({
    type: POST_VALIDATE_FIELDS,
    payload: {
        data: query,
        body: form,
        otherData
    }
})

/* REASSIGN */
export const postReassignTask = ({query, form, context}) => ({
    type: POST_REASSIGN_TASK,
    payload:{
        data: query,
        body: form,
        otherData: {
            context
        }
    }
})

/* REASSIGN Document*/
export const postReassignTaskDocument = ({query, form, successFunction, context}) => ({
    type: POST_REASSIGN_TASK_DOCUMENT,
    payload:{
        data: query,
        body: form,
        otherData: {
            successFunction,
            context
        }
    }
})

/* ADD DOCUMENT */
export const fetchNewDocument = ({query, form}) => ({
    type: FETCH_ADD_DOCUMENT,
    payload: {
        data: query,
        body: form
    }
})

export const setNewDocument = ({data, index}) => ({
    type: SET_ADD_DOCUMENT,
    payload: {
        data, index
    }
})

/* RETRY_DOCUMENT */
export const postRetryDocument = ({query, form}) => ({
    type: POST_RETRY_DOCUMENT,
    payload: {
        data: query,
        body: form
    }
})
