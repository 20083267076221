import {API_ERROR, API_SUCCESS, apiRequest} from "../../../actions/core/api/api.actions";
import {setError, setLoader} from "../../../actions/app/ui/ui.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {setDocuments, FETCH_DOCUMENTS} from "../../../actions/app/support/documents.actions";

export const documentsMiddleware = ({dispatch}) => next => action => {
    next(action)

    switch (action.type){
        case FETCH_DOCUMENTS:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: FETCH_DOCUMENTS}),
                setLoader({state: true, entity: FETCH_DOCUMENTS})
            ])
            break

        case `${FETCH_DOCUMENTS} ${API_SUCCESS}`:
            next([
                setDocuments({data: action.payload.data}),
                setError({state: false, entity: FETCH_DOCUMENTS}),
                setLoader({state: false, entity: FETCH_DOCUMENTS})
            ])
            break

        case `${FETCH_DOCUMENTS} ${API_ERROR}`:
            next([
                setNotification({
                    entity: FETCH_DOCUMENTS,
                    html: `<div><p>${action.payload.data.response?.data.reason || "Erreur lors de la récupération des fiches pratiques"}</p></div>`,
                    icon: 'error',
                    title: 'Attention !',
                    confirmButtonText: 'J\'ai compris'
                }),
                setError({state: true, entity: FETCH_DOCUMENTS}),
                setLoader({state: false, entity: FETCH_DOCUMENTS})
            ])
            break

        default:
            break
    }
    return null
}
