import {createSelector} from 'reselect'

const taskSelector = state => state.task

export const getFirstTask = createSelector(
    taskSelector,
    task => task
)

export const getOrdreFirstTask = createSelector(
    taskSelector,
    task => task.ordre
)

export const getPopupIdFirstTask = createSelector(
    taskSelector,
    task => task.id
)

export const getCodeProduitContratFirstTask = createSelector(
    taskSelector,
    task => task.contract?.vehicule.codeProduit
)

export const getFilesUrlFirstTask = createSelector(
    taskSelector,
    task => task.files_url || []
)

export const getIdContractFirstTask = createSelector(
    taskSelector,
    task => task.contract?.id
)

export const getTiersIdTask = createSelector(
    taskSelector,
    task => task.tiers_id
)

export const getTerminateUrlTask = createSelector(
    taskSelector,
    task => task.terminate_url
)
