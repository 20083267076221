import axios from 'axios'
import * as Sentry from "@sentry/react"
import {API_REQUEST, apiError, apiSuccess} from '../../../actions/core/api/api.actions'
import {setIsLogin, setMaintenance} from '../../../actions/app/ui/ui.actions'
import {localStorageAMO} from '../../../selectors/localStorage/localStorage'
import {clearLocalStorage} from '../../../actions/core/localStorage/localStorage.actions'

export const apiMiddleware = ({dispatch}) => next => action => {
    next(action)

    if (action.type.includes(API_REQUEST)){
        const { meta: { url, method, entity, otherData, header = 'application/x-www-form-urlencoded' }, data } = action.payload

        const headers = { 'content-type': header }
        const login = (otherData && otherData.login) || false

        if(localStorageAMO && !login){
            headers.authorization = localStorageAMO.token
        }
        axios({method, headers, url, data})
            .then (response => {
                dispatch(apiSuccess({response: response.data, entity, otherData}))
            })
            .catch(error => {
                if (error.response){
                    switch (error.response.status){

                        case 401:
                            dispatch(apiError({error, entity, otherData}))
                            dispatch(setIsLogin({state: false, entity}))
                            dispatch(clearLocalStorage({entity}))
                            break

                        case 503:
                            dispatch(apiError({error, entity, otherData}))
                            dispatch(setMaintenance({state: true, entity}))
                            break

                        default:
                            dispatch(apiError({error, entity, otherData}))
                    }

                    if (error.response.data?.code !== 404){
                        Sentry.captureMessage(`${error.message.data?.code} ${error.response.data?.source || 'espace-afn'} - ${error.response.data?.error}`)
                    }

                } else {
                    dispatch(apiError({error, entity, otherData})) // OPTION REQUEST
                }
            })
    }
}
