import React, {useEffect, useState} from 'react';
import Text from "../../../../../Commun/Inputs/Text/Text";
import _ from "lodash";

const ReassignTask = (props) => {
    const {reassignDocument, setFieldValue, handleChange, handleBlur, values, chrono_id, contract_id, file_id, indexFile, maxIndexFile, queryReassign, queryReassignDocument, popup_id, terminateUrl, postReassignTask, postReassignTaskDocument, paniereFormReassign, formContext} = props
    const [errorComment, setErrorComment] = useState(false)

    useEffect(() => {
        document.getElementById('reassign').scrollIntoView({behavior: 'smooth'})
    }, [])

    const handleClickReassignDoc = () => {
        postReassignTaskDocument({
            query: _.replace(queryReassignDocument, '{id}', chrono_id),
            form: {contract: contract_id, file_id, indexFile, maxIndexFile, terminateUrl, commentaire: values?.commentaire_reassign, paniere: paniereFormReassign},
            successFunction: () => {setFieldValue('commentaire_reassign', ''); },
            context: formContext
        })
    }

    const handleClickReassignTask = () => {
        postReassignTask({query: _.replace(queryReassign, '{id}', popup_id), form: {keep_old: 0, paniere: paniereFormReassign, async: true, 'commentaire': values?.commentaire_reassign}, context: formContext})
    }

    return (
        <div id={'reassign'}>
            <div className={'mt-3 mb-3'}>
                {reassignDocument && <p className={'mb-3'}>Réaffecter le document en {paniereFormReassign}</p>}

                <Text
                    isValid={values.data?.['commentaire_reassign']?.state}
                    type={'text'}
                    name={'commentaire_reassign'}
                    label={'Commentaire'}
                    maxLength={'500'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.commentaire_reassign}
                />
            </div>

            {
                !!errorComment && <div className={'alert-danger alert'} dangerouslySetInnerHTML={{__html: errorComment}} />
            }

            <button
                type={'button'}
                onClick={() => {
                    if(!!values.commentaire_reassign){
                        setErrorComment(false)
                        reassignDocument ? handleClickReassignDoc() : handleClickReassignTask()
                    } else {
                        setErrorComment("Le commentaire est obligatoire")
                    }
                }}
                className={'btn btn-outline-secondary btn-reassign me-2'}
            >
                {
                    reassignDocument ? 'Réaffecter le document' : 'Réaffecter'
                }
            </button>
        </div>
    );
};

export default ReassignTask;
