import React from 'react';
import './small-spinner.scss'

const SmallSpinner = ({large}) => {
    return (
        <div className="d-flex justify-content-center">
            <div className={`spinner-border text-info ${large ? 'large' : ''}`} role="status">
                <span className="sr-only"></span>
            </div>
        </div>
    );
};

export default SmallSpinner;
