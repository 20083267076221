import React from 'react';
import _ from 'lodash'
import {Routes, Route, Navigate} from 'react-router-dom'
import PageRoutes from "./PageRoutes";

const Page = () => {
    return (
        <Routes>
            {
                _.map(PageRoutes, (route, i) => (
                    <Route path={route.path} element={route.main} key={i} />
                ))
            }
            <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
    );
};

export default Page;
