import React from 'react';
import PropTypes from 'prop-types'
import SmallSpinner from "../SmallSpinner";
import logo from '../../../../assets/logo/checkdoc-blue.png';

const LargeSpinner = () => {
    return (
        <div className={'text-center my-5'}>
            <SmallSpinner large/>
        </div>
    );
};

LargeSpinner.defaultProps = {
    message: ""
}

LargeSpinner.propTypes = {
    message: PropTypes.string
}

export default LargeSpinner;
