import React, {useEffect} from 'react';
import _ from 'lodash'
import Select from "../../../../../Commun/Inputs/Select/Select";
import {ListDocuments} from "./ListDocuments";

const TypeDocument = (props) => {
    const {values, errors, postTypageDocument, query, idContrat, setFieldValue, indexFile, file, codeProduit, formContext} = props

    useEffect(() => {
        setFieldValue('doc_type', file?.fields[0]?.doc_type ?? "")
        if( codeProduit !== '7311_PVE' && codeProduit !== '7311_PVK' && (file.fields[0]!== undefined && file.fields[0].doc_type === 'V') ){
            handleChangeType('B6')
        } else if(!file.code){
            handleChangeType('B0')
        }
    }, [indexFile, file])

    const handleChangeType = (value) => {
        let valueDoc = value
        if( codeProduit !== '7311_PVE' && codeProduit !== '7311_PVK' && (value === 'V') ){
            valueDoc = 'B6'
        }
        postTypageDocument({query, form: {doc_type: valueDoc, contract: idContrat, indexFile, form_type_origin: file.code || 'L', form_context: formContext, file_id: file.id}})
        setFieldValue('doc_type', valueDoc)
        setFieldValue('data', {})
    }

    return (
        <>
            <div className={`${formContext === 'RES' ? 'col-12' : 'col-9'}`}>
                <Select
                    label={'Type du document'}
                    name={`doc_type`}
                    error={errors.doc_type}
                    value={values.doc_type}
                    onChange={(e) => handleChangeType(e.target.value)}
                >
                    <option value={""}>Sélectionnez le type de document</option>
                    {
                        _.orderBy(ListDocuments(formContext, codeProduit), ['libelle'], ['asc']).map(doc => (
                            <option value={doc.code} key={doc.code}>{doc.libelle}</option>
                        ))
                    }
                </Select>
            </div>
        </>
    );
};

export default TypeDocument;
