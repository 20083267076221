import {SET_NEW_FORM_AFTER_TYPAGE} from "../tasks/tasks.actions";

export const UI = '[Ui]'

export const SET_LOADER = `${UI} SET_LOADER`
export const SET_IS_LOGIN = `${UI} SET_IS_LOGIN`
export const SET_MAINTENANCE = `${UI} SET_MAINTENANCE`
export const SET_ERROR = `${UI} SET_ERROR`
export const SET_INDEX_FILE = `${UI} SET_INDEX_FILE`
export const SET_MAX_INDEX_FILE = `${UI} SET_MAX_INDEX_FILE`
export const SET_NEW_FICHE_PRATIQUE_AFTER_TYPAGE = `${UI} SET_NEW_FICHE_PRATIQUE_AFTER_TYPAGE`

export const setLoader = ({state, entity}) => ({
    type: `${entity} ${SET_LOADER}`,
    payload: state,
    meta: entity
})

export const setError = ({state, entity}) => ({
    type: `${entity} ${SET_ERROR}`,
    payload: state,
    meta: entity
})

export const setIsLogin = ({state, entity}) => ({
    type: `${entity} ${SET_IS_LOGIN}`,
    payload: state
})

export const setMaintenance = ({state, entity}) => ({
    type: `${entity} ${SET_MAINTENANCE}`,
    payload: state
})

export const setIndexFile = ({data, entity}) => ({
    type: `${entity} ${SET_INDEX_FILE}`,
    payload: data
})

export const setMaxIndexFile = ({data, entity}) => ({
    type: `${entity} ${SET_MAX_INDEX_FILE}`,
    payload: data
})
