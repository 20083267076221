import React, {useState} from 'react';
import LogoCheckDoc from '../../../../assets/logo/checkdoc-white.png'
import {Link} from "react-router-dom";
import Support from "../Support/Support";

const Header = (props) => {
    const { name, children } = props
    const [ show, setShow ] = useState('')

    const deconnexion = () => {
        window.localStorage.clear()
        window.location.reload(true)
    }

    const handleClick = () => {
        setShow('show')
    }

    return (
        <>
            <div className={"row bg-dark p-2 header"}>
                <div className="container-fluid">
                    <div className={'row d-flex justify-content-between'}>
                        <div className="col-2">
                            <Link to={'/'}>
                                <img src={LogoCheckDoc} alt={'Check Doc'} width={130}/>
                            </Link>
                        </div>
                        <div className={'col-2'}>
                            <button className="btn btn-help mt-3"
                                    type="button"
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#faqSidebar"
                                    aria-controls="faqSidebar" onClick={() => handleClick()}
                            >
                                <span><i className="bi bi-question ps-1"/></span> <p>Besoin d'aide ?</p>
                            </button>
                        </div>
                        {children}
                        {window.location.pathname === '/' &&
                            <div className="col-2 pe-4 pt-2 text-end align-middle user-log">
                                <div className={'user-content'}>
                                    <p className={'text-white m-0 fw-bold f-12'}>{name}</p>
                                    <Link to="/logout" className={'m-0 d-inline-block cursor-pointer text-decoration-none fw-bolder outer-title f-10'}> Se déconnecter</Link>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <Support show={show} setShow={setShow}/>
        </>
    );
};

export default Header;
