import {connect} from 'react-redux'
import {compose} from 'redux'
import TypeDocument from "./TypeDocument";
import {postTypageDocument} from "../../../../../../redux/actions/app/tasks/tasks.actions";
import {getIdContractFirstTask} from "../../../../../../redux/selectors/tasks/tasks.selectors";
import {getTasksChangeTypeLink} from "../../../../../../redux/selectors/links/links.selectors";
import {getIndexFileUi} from "../../../../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = state => {
    return {
        query: getTasksChangeTypeLink(state),
        idContrat: getIdContractFirstTask(state),
        indexFile: getIndexFileUi(state)
    }
}

const mapDispatchToProps = {
    postTypageDocument
}

const TypeDocumentContainer = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(TypeDocument)

export default TypeDocumentContainer
