import {API_ERROR, API_SUCCESS, apiRequest} from "../../../actions/core/api/api.actions";
import {setError, setLoader} from "../../../actions/app/ui/ui.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {setFaq, FETCH_FAQ} from "../../../actions/app/support/faq.actions";

export const faqMiddleware = ({dispatch}) => next => action => {
    next(action)

    switch (action.type){
        case FETCH_FAQ:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: FETCH_FAQ}),
                setLoader({state: true, entity: FETCH_FAQ})
            ])
            break

        case `${FETCH_FAQ} ${API_SUCCESS}`:
            next([
                setFaq({data: action.payload.data}),
                setError({state: false, entity: FETCH_FAQ}),
                setLoader({state: false, entity: FETCH_FAQ})
            ])
            break

        case `${FETCH_FAQ} ${API_ERROR}`:
            next([
                setNotification({
                    entity: FETCH_FAQ,
                    html: `<div><p>${action.payload.data.response?.data.reason || "Erreur lors de la récupération de la FAQ"}</p></div>`,
                    icon: 'error',
                    title: 'Attention !',
                    confirmButtonText: 'J\'ai compris'
                }),
                setError({state: true, entity: FETCH_FAQ}),
                setLoader({state: false, entity: FETCH_FAQ})
            ])
            break
        
        default:
            break
    }
    return null
}
