import _ from 'lodash';
import moment from 'moment';
import { NumberFormatBase } from 'react-number-format';
import { normalizeDate, normalizeYear } from '../../../../utils/normalize';

const Text = (props) => {
    const {
        type,
        label,
        value,
        error,
        touched,
        required,
        normalize,
        onChange,
        InputLabelProps,
        subLabel,
        additional_label,
        maxLength,
        values,
        file,
        isValid,
        ...other
    } = props;
    const inputStyle = isValid === '1' ? 'success-input' : isValid === '0' ? 'error-input' : 'no-input';
    let pattern = null;
    const dateFutureOk =
        _.includes(['Z', 'W', 'X'], file?.doc_type) ||
        _.includes(['N', 'P', 'M'], file?.form_type) ||
        file?.belair_field === 'A_FIN_SEJ';

    const changeData = (e) => {
        if (type === 'date') {
            e.target.value = normalizeDate(e.target.value);
        } else if (type === 'year') {
            e.target.value = normalizeYear(e.target.value);
        } else if (type === 'iban') {
            e.target.value = removeFormatting(e.target.value);
        } else {
            e.target.value = normalize(e.target.value);
        }

        onChange(e);
    };

    if (type === 'date') {
        pattern = '\\d{1,2}/\\d{1,2}/\\d{4}';
    }

    const isValidInputCharacter = (char) => /^[a-z0-9]$/i.test(char);

    const formatIban = (value) => {
        return value
            .replace(/\s+/g, '')
            .replace(/([a-z0-9]{4})/gi, '$1 ')
            .trim()
            .toLocaleUpperCase();
    };

    const removeFormatting = (value) => value.replace(/\s+/gi, '');

    const getCaretBoundary = (value) =>
        Array(value.length + 1)
            .fill(0)
            .map(() => true);

    return (
        <div className={'form-floating'}>
            {subLabel && <small className={'float-right'}>{subLabel}</small>}
            {type === 'iban' ? (
                <NumberFormatBase
                    id={`floating${label}`}
                    type="text"
                    required={required}
                    value={value}
                    format={formatIban}
                    removeFormatting={removeFormatting}
                    isValidInputCharacter={isValidInputCharacter}
                    getCaretBoundary={getCaretBoundary}
                    onKeyDown={(e) =>
                        !/^(?:[a-z0-9]|Backspace|Delete|Home|End|ArrowLeft|ArrowRight|Shift|CapsLock|Control|NumLock|Tab|Paste|Redo|Undo)$/i.test(
                            e.key,
                        ) && e.preventDefault()
                    }
                    onChange={(e) => changeData(e)}
                    className={'form-control ' + (touched ? (error ? 'is-invalid ' : ' is-valid ') : '') + inputStyle}
                    {...other}
                />
            ) : (
                <input
                    id={`floating${label}`}
                    type={type === 'date' || type === 'year' ? 'text' : type}
                    required={required}
                    value={value}
                    maxLength={type === 'year' ? '4' : maxLength}
                    onChange={(e) => changeData(e)}
                    onWheel={(e) => e.target.blur()}
                    className={'form-control ' + (touched ? (error ? 'is-invalid ' : ' is-valid ') : '') + inputStyle}
                    pattern={pattern}
                    {...other}
                />
            )}
            {label && (
                <label htmlFor={`floating${label}`} {...InputLabelProps}>
                    <b>{additional_label}</b> {label}
                </label>
            )}
            {!dateFutureOk &&
                type === 'date' &&
                value.length === 10 &&
                (!moment(value, 'DD/MM/YYYY').isValid() || moment(value, 'DD/MM/YYYY') > moment()) && (
                    <div className={'alert-danger alert mb-0'}>La date doit être inférieure à la date du jour</div>
                )}
            {touched && error && <small className="invalid-feedback">{error}</small>}
            {file &&
                file.input_name === 'RVEHOII-B_IMMAT' &&
                !value &&
                (!values.data?.[`${file.input_name}`]?.state || values.data?.[`${file.input_name}`].state === '1') && (
                    <div>
                        <div className={'alert-danger alert mb-0'}>Merci de renseigner une immatriculation</div>
                    </div>
                )}
        </div>
    );
};

Text.defaultProps = {
    normalize: (v) => v,
};

export default Text;
