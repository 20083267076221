import React from 'react'
import HomeContainer from "./Home/HomeContainer";
import WorkSpaceContainer from "./WorkSpace/WorkSpaceContainer";
import LogoutContainer from "./Logout/LogoutContainer";
import AllDocumentsContainer from "./AllDocuments/AllDocumentsContainer";

const PageRoutes = [
    {
        path: '/',
        title: 'Home Page',
        permission: 'all',
        main: <HomeContainer />
    },
    {
        path: '/ma-tache/:context',
        title: 'Traitement de la tache',
        permission: 'all',
        main: <WorkSpaceContainer origin='checkdoc' />
    },
    {
        path: '/ma-tache/:context/:id',
        title: 'Traitement de la tache',
        permission: 'all',
        main: <WorkSpaceContainer origin='conseiller' />
    },
    {
        path: '/ma-tache/:context/:id/mes-documents',
        title: 'Tous les documents',
        permission: 'all',
        main: <AllDocumentsContainer />
    },
    {
        path: '/logout',
        title: 'Logout',
        permission: 'all',
        main: <LogoutContainer />
    }
]

export default PageRoutes
