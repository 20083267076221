import React, {forwardRef, useEffect, useMemo, useState} from 'react';
import {localStorageAMO} from "../../../redux/selectors/localStorage/localStorage";
import SmallSpinner from "../Spinners/SmallSpinner";

const APIImage = forwardRef((props, ref) => {
    const [error, setError] = useState(false)
    const [externalImage, setExternalImage] = useState(null)
    const [objectUrlImg, setObjectUrlImg] = useState(null)

    useEffect(() => {
        // if (props.src.indexOf('pass.april-moto.com') < 0 && props.src.indexOf('pass-recette.april-moto.com') < 0 && props.src.indexOf('pass-live.april-moto.com') < 0) {
        //     setExternalImage(true)
        //     return
        // }

        let url = new URL(props.src)

        url.searchParams.delete('access_token');
        url = url.toString()

        const tokenUser = localStorageAMO.token

        let headers = new Headers();
        headers.append('Authorization', tokenUser);

        fetch(url, {headers})
            .then((response) => {
                if (response.ok) {
                    return response.blob();
                }
                setError(true)
                return undefined
            })
            .then(blob => {
                if (blob) {
                    setObjectUrlImg(window.URL.createObjectURL(blob))
                }
            })
    }, [props.src]);

    if (externalImage) {
        return <img {...props} />
    }

    if (error) {
        return <p>Erreur</p>
    }

    if (!objectUrlImg) {
        return <SmallSpinner {...props} />
    }

    const {src, ...otherProps} = props;

    return <img src={objectUrlImg} ref={(el) => {
        ref && ref(el)
    }} {...otherProps} />
})

export default APIImage;
